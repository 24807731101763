import clsx from 'clsx';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, onChange, className, disabled }) => {
  return (
    <div
      className={clsx(
        'flex flex-row items-center gap-2 transition',
        disabled ? 'opacity-40 cursor-not-allowed' : 'opacity-100 cursor-pointer',
        className
      )}
      onClick={() => onChange(!checked)}>
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) =>
          disabled
            ? () => {
                /* do nothing */
              }
            : onChange(e.target.checked)
        }
        className="w-4 h-4 rounded-sm border border-border focus:ring-0 focus:outline-none accent-dark"
      />
      <div>{label}</div>
    </div>
  );
};

export default Checkbox;

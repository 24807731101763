import { Constants } from '@jobmatic/shared/utils';
import { useDownloadInvoice, useGetAllInvoices } from '../../../hooks/query/Invoice';
import { useMemo, useState } from 'react';
import { useShallowCompareEffect } from 'react-use';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';

export type AdvertiserInvoicesFilter = Parameters<typeof useDashboardAdvertiserDetailsInvoicesController>[2];
export type AdvertiserInvoicesOrder = { column: keyof typeof ADVERTISER_INVOICES_COLUMNS; direction: 'asc' | 'desc' };

export const ADVERTISER_INVOICES_COLUMNS = {
  count: { name: '#', sortable: false },
  invoiceDate: { name: 'RG-Datum', sortable: true },
  dueDate: { name: 'Fällig am', sortable: true },
  jobBaseServiceId: { name: 'Ins', sortable: true },
  invoiceNumber: { name: 'Rechnung', sortable: true },
  state: { name: 'Status', sortable: true },
  netPrice: { name: 'Netto', sortable: true },
  grossPrice: { name: 'Brutto', sortable: true },
  paymentMethod: { name: 'Bez.', sortable: true },
};

const columnToSortField = (
  column: keyof typeof ADVERTISER_INVOICES_COLUMNS
): NonNullable<Parameters<typeof useGetAllInvoices>[0]['sort']>['field'] => {
  switch (column) {
    case 'invoiceDate':
      return 'invoiceDate';
    case 'dueDate':
      return 'dueDate';
    case 'jobBaseServiceId':
      return 'jobBaseServiceId';
    case 'invoiceNumber':
      return 'invoiceNumber';
    case 'state':
      return 'state';
    case 'netPrice':
      return 'invoiceAmountNet';
    case 'grossPrice':
      return 'invoiceAmountGross';
    case 'paymentMethod':
      return 'paymentMethod';
    default:
      return 'id';
  }
};

const useDashboardAdvertiserDetailsInvoicesController = (
  advertiserId: number,
  defaultOrder: AdvertiserInvoicesOrder = { column: 'invoiceNumber', direction: 'desc' },
  filter?: Parameters<typeof useGetAllInvoices>[0]['filter'],
  filterOperator: 'AND' | 'OR' = 'AND'
) => {
  const [sort, setSort] = useState(defaultOrder);
  const [page, setPage] = useState(1);
  const [invoicePdfWindow, setInvoicePdfWindow] = useState<Window | null>(null);
  const { data: invoicesData, isLoading } = useGetAllInvoices({
    page,
    filter: [...(filter ?? []), { field: 'advertiserId', operator: 'equals', value: advertiserId }],
    filterOperator,
    sort: {
      field: columnToSortField(sort.column),
      direction: sort.direction,
    },
  });
  const pageCount = useMemo(
    () => (invoicesData ? Math.ceil(invoicesData.count / Constants.ADMIN_PAGE_ENTRY_LIMIT) : 0),
    [invoicesData]
  );

  const { mutate: downloadInvoice } = useDownloadInvoice({
    onSuccess: (data) => {
      if (!invoicePdfWindow) return;
      invoicePdfWindow.location = data.link;
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });

  const handleInvoiceClick = (id: number) => {
    const popup = window.open(
      `about:blank`,
      `invoice-${id}`,
      'width=1200,height=800,scrollbars=yes,resizable=yes,status=yes,dependent=yes'
    );
    if (popup) popup.focus();
    setInvoicePdfWindow(popup);
    downloadInvoice({ id });
  };

  const handleSort = (column: keyof typeof ADVERTISER_INVOICES_COLUMNS) => {
    if (column === sort.column) setSort({ ...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    else setSort({ column, direction: 'asc' });
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage === page || newPage < 1 || newPage > pageCount) return;
    setPage(newPage);
  };

  useShallowCompareEffect(() => {
    setSort(defaultOrder);
  }, [defaultOrder]);

  return {
    invoices: invoicesData ? invoicesData.invoices : [],
    isLoading,
    pages: pageCount,
    page,
    currentSort: sort.column,
    handleInvoiceClick,
    handleSort,
    handlePageChange,
  };
};

export default useDashboardAdvertiserDetailsInvoicesController;

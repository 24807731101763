import { Helmet } from 'react-helmet';
import Button from '../../../components/Button';
import useDashboardAdminController from '../../../controllers/Dashboard/Admin';
import clsx from 'clsx';
import Checkbox from '../../../components/Checkbox';
import Input from '../../../components/Input';
import InputGroup from '../../../components/InputGroup';

const DashboardAdminMainView: React.FC = () => {
  const controller = useDashboardAdminController();

  return (
    <>
      <Helmet>
        <title>[JM] Administration</title>
      </Helmet>
      <div className="px-8 py-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="md:flex-1">
            <h2 className="text-xl font-bold mb-2">Einstellungen</h2>
            <div className="flex flex-col gap-4 items-start mb-8">
              <Checkbox
                label="Global Auto-On"
                checked={controller.globalAutoOn}
                onChange={controller.setGlobalAutoOn}
              />
              <div className="flex flex-row items-end gap-2">
                Umsatzsteuer (%):{' '}
                <Input
                  placeholder="19"
                  value={controller.vatRate}
                  onChange={controller.setVatRate}
                  inputMode="numeric"
                />
              </div>
              <Button
                title="Speichern"
                onClick={() => controller.handleSaveConfig()}
                disabled={controller.isUpdatingConfig}
                className={clsx(controller.isUpdatingConfig && '!cursor-wait')}
              />
            </div>
            <h2 className="text-xl font-bold mb-2">Webflow</h2>
            <strong>Status</strong>:{' '}
            {!!controller.webflowStatus && (
              <span>
                {controller.webflowStatus.counts.QUEUED + controller.webflowStatus.counts.RECEIVED} Aufträge in der
                Warteschlange, {controller.webflowStatus.counts.EXECUTING + controller.webflowStatus.counts.RUNNING}{' '}
                Aufträge werden ausgeführt
                {!!controller.webflowStatus?.panic && <span className="text-error"> [PANIC]</span>}
              </span>
            )}
            <div className="flex flex-row gap-4 mt-4">
              <Button
                title="Stop Sync"
                disabled={!controller.webflowStatus || controller.isActivatingPanic || controller.webflowStatus.panic}
                onClick={() => controller.panicWebflow()}
                className={clsx(controller.isActivatingPanic && '!cursor-wait')}
              />
              <Button
                title="Resync"
                disabled={!controller.webflowStatus?.panic || controller.isActivatingPanic || controller.isResyncing}
                onClick={() => controller.resyncWebflow()}
                className={clsx(controller.isResyncing && '!cursor-wait')}
              />
            </div>
            <h2 className="text-xl font-bold mb-2 mt-8">Jobletter</h2>
            <Button
              title="An alle versenden"
              disabled={controller.isTriggeringJobletter}
              onClick={() => controller.triggerJobletter()}
              className={clsx(controller.isTriggeringJobletter && '!cursor-wait')}
            />
            <h2 className="text-xl font-bold mb-2 mt-8">System-Profiler</h2>
            <Button
              title="CPU- und RAM-Profil erstellen"
              disabled={controller.isProfilingApp}
              onClick={() => controller.profileApp({ profileDuration: 10 })}
              className={clsx(controller.isProfilingApp && '!cursor-wait')}
            />
          </div>
          <div className="md:flex-1">
            <h2 className="text-xl font-bold mb-2 mt-8 md:mt-0">Wartungsmodus</h2>
            <div className="flex flex-col gap-4 items-start mb-8">
              <span>
                Der Wartungsmodus ist derzeit{' '}
                <strong className={clsx(controller.maintenanceEnabled && 'text-error')}>
                  {controller.maintenanceEnabled ? 'aktiv' : 'inaktiv'}
                </strong>
                .
              </span>
              <textarea
                className="w-full px-3 py-2 border border-border rounded h-24"
                onChange={(e) => controller.setMaintenanceReason(e.target.value)}
                placeholder="Nachricht"
                value={controller.maintenanceReason}
                disabled={controller.isUpdatingConfig}
              />
              <div className="flex flex-col sm:flex-row gap-4">
                <Button
                  title={controller.maintenanceEnabled ? 'Nachricht aktualisieren' : 'Aktivieren'}
                  onClick={() => controller.handleEnableMaintenance()}
                  disabled={controller.isUpdatingConfig}
                  className={clsx(controller.isUpdatingConfig && '!cursor-wait')}
                />
                <Button
                  title="Deaktivieren"
                  onClick={() => controller.handleDisableMaintenance()}
                  disabled={!controller.maintenanceEnabled || controller.isUpdatingConfig}
                  className={clsx(controller.isUpdatingConfig && '!cursor-wait')}
                />
              </div>
            </div>
            <h2 className="text-xl font-bold mb-2 mt-8 md:mt-0">Auto-Views pro Tag</h2>
            <div className="flex flex-col gap-4 items-start mb-8 w-full">
              {controller.services.map((service) => (
                <InputGroup label={service.name} className="w-full" key={'views-per-day-' + service.id}>
                  <Input
                    className="w-full"
                    value={controller.viewsPerDay[service.id.toString()] ?? ''}
                    onChange={(value) =>
                      controller.setViewsPerDay((draft) => {
                        draft[service.id.toString()] = parseInt(value);
                      })
                    }
                    type="number"
                  />
                </InputGroup>
              ))}
              <Button
                title="Speichern"
                onClick={() => controller.handleUpdateViewsPerDay()}
                disabled={controller.isUpdatingViewsPerDay}
                className={clsx(controller.isUpdatingViewsPerDay && '!cursor-wait')}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAdminMainView;

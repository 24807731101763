import { Advertiser, VisitorTrack } from '@prisma/client';
import clsx from 'clsx';
import { useMemo } from 'react';

interface VisitorTrackerRowProps {
  visitor: VisitorTrack & { advertiser?: Advertiser | null };
  extended?: boolean;
  onPressAdvertiserName?: (advertiserId: number) => void;
  tdClassName?: string;
}
const VisitorTrackerRow: React.FC<VisitorTrackerRowProps> = ({
  visitor,
  extended,
  onPressAdvertiserName,
  tdClassName,
}) => {
  const url = useMemo(() => new URL(visitor.url), [visitor.url]);

  return (
    <tr>
      <td className={clsx('whitespace-nowrap', tdClassName)}>{visitor.serviceId}</td>
      <td
        className={clsx(
          'whitespace-nowrap',
          visitor.advertiserId && onPressAdvertiserName && 'cursor-pointer hover:underline',
          tdClassName
        )}
        onClick={() => visitor.advertiserId && onPressAdvertiserName?.(visitor.advertiserId)}>
        {visitor.advertiser ? `${visitor.advertiser.baseFirstName} ${visitor.advertiser.baseLastName}` : 'Gast'}
      </td>
      <td className={clsx('whitespace-nowrap', tdClassName)}>{visitor.advertiser?.loginCount ?? ''}</td>
      <td className={clsx('whitespace-nowrap', tdClassName)}>
        {visitor.advertiser ? `${visitor.advertiser.baseCountry} ${visitor.advertiser.baseZip}` : ''}
      </td>
      <td className={clsx('whitespace-nowrap', tdClassName)}>{visitor.advertiser?.email ?? visitor.ip}</td>
      <td className={clsx('whitespace-nowrap', tdClassName)}>{`${url.pathname}${
        url.searchParams.toString().length > 0 ? '?' : ''
      }${url.searchParams.toString()}`}</td>
      {extended && (
        <>
          <td className={clsx('whitespace-nowrap', tdClassName)}>{visitor.ip}</td>
          <td className={clsx('whitespace-nowrap', tdClassName)}>{visitor.userAgent}</td>
        </>
      )}
    </tr>
  );
};

export default VisitorTrackerRow;

import clsx from 'clsx';

interface AdvertiserDetailsRowProps {
  title: string;
  className?: string;
  children: React.ReactNode;
}

const AdvertiserDetailsRow: React.FC<AdvertiserDetailsRowProps> = ({ title, children, className }) => {
  return (
    <div className={clsx('flex flex-row gap-4 items-start', className)}>
      <div className="w-36">{title}</div>
      <div className="flex-grow">{children}</div>
    </div>
  );
};

export default AdvertiserDetailsRow;

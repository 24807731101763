import { Constants } from '@jobmatic/shared/utils';
import { useGetAllAdvertisers } from '../../hooks/query/Advertiser';
import { useMemo, useState } from 'react';
import { useShallowCompareEffect } from 'react-use';

export type AdvertiserFilter = Parameters<typeof useDashboardAdvertisersController>[1];
export type AdvertiserOrder = { column: keyof typeof ADVERTISER_COLUMNS; direction: 'asc' | 'desc' };

export const ADVERTISER_COLUMNS = {
  count: { name: '#', sortable: false },
  id: { name: 'ID', sortable: true },
  baseService: { name: 'Reg', sortable: true },
  lastLoginService: { name: 'LL', sortable: true },
  kind: { name: 'Art', sortable: true },
  businessName: { name: 'Inserent', sortable: true },
  salutation: { name: 'Anrede', sortable: true },
  firstName: { name: 'Vorname', sortable: true },
  lastName: { name: 'Nachname', sortable: true },
  country: { name: 'Land', sortable: true },
  zipCode: { name: 'PLZ', sortable: true },
  city: { name: 'Ort', sortable: true },
  lastLoginAtDate: { name: 'Letzter Login', sortable: true },
  createdAt: { name: 'Registriert', sortable: true },
  deletedAt: { name: 'Gelöscht', sortable: true },
  lastLoginAtTime: { name: 'Uhrzeit', sortable: true },
  loginCount: { name: 'Logins', sortable: true },
  jobsOnline: { name: 'Anz. Onl.', sortable: true },
  jobsTotal: { name: 'Anz. Ges.', sortable: true },
  discountPercentage: { name: '%', sortable: true },
  email: { name: 'E-Mail-Adresse', sortable: true },
};

const columnToSortField = (
  column: keyof typeof ADVERTISER_COLUMNS
): NonNullable<Parameters<typeof useGetAllAdvertisers>[0]['sort']>['field'] => {
  switch (column) {
    case 'id':
      return 'id';
    case 'baseService':
      return 'baseServiceId';
    case 'kind':
      return 'type';
    case 'businessName':
      return 'baseBusinessName';
    case 'salutation':
      return 'baseSalutation';
    case 'firstName':
      return 'baseFirstName';
    case 'lastName':
      return 'baseLastName';
    case 'country':
      return 'baseCountry';
    case 'zipCode':
      return 'baseZip';
    case 'city':
      return 'baseCity';
    case 'createdAt':
      return 'createdAt';
    case 'deletedAt':
      return 'deletedAt';
    case 'loginCount':
      return 'loginCount';
    case 'email':
      return 'email';
    case 'discountPercentage':
      return 'discountPercentage';
    case 'jobsOnline':
      return 'jobsActive';
    case 'jobsTotal':
      return 'jobsTotal';
    case 'lastLoginAtDate':
    case 'lastLoginAtTime':
      return 'lastLogin';
    case 'lastLoginService':
      return 'lastLoginServiceId';
    default:
      return 'id';
  }
};

/** NOTE: Search functionality is handled directly in the view. */
const useDashboardAdvertisersController = (
  defaultOrder: AdvertiserOrder = { column: 'id', direction: 'desc' },
  filter?: Parameters<typeof useGetAllAdvertisers>[0]['filter'],
  filterOperator: 'AND' | 'OR' = 'AND'
) => {
  const [sort, setSort] = useState(defaultOrder);
  const [page, setPage] = useState(1);
  const { data: advertisersData, isLoading } = useGetAllAdvertisers({
    page,
    filter,
    filterOperator,
    sort: {
      field: columnToSortField(sort.column),
      direction: sort.direction,
    },
  });
  const pageCount = useMemo(
    () => (advertisersData ? Math.ceil(advertisersData.count / Constants.ADMIN_PAGE_ENTRY_LIMIT) : 0),
    [advertisersData]
  );

  const handleAdvertiserClick = (id: number, popupSubPage?: string) => {
    const popup = window.open(
      `/dashboard/inserenten/${id}${popupSubPage ? `/${popupSubPage}` : ''}`,
      `advertiser-${id}`,
      'width=1200,height=800,scrollbars=yes,resizable=yes,status=yes,dependent=yes'
    );
    if (popup) popup.focus();
  };

  const handleSort = (column: keyof typeof ADVERTISER_COLUMNS) => {
    if (column === sort.column) setSort({ ...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    else setSort({ column, direction: 'asc' });
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage === page || newPage < 1 || newPage > pageCount) return;
    setPage(newPage);
  };

  useShallowCompareEffect(() => {
    setSort(defaultOrder);
  }, [defaultOrder]);

  return {
    advertisers: advertisersData ? advertisersData.advertisers : [],
    isLoading,
    pages: pageCount,
    page,
    currentSort: sort.column,
    handleAdvertiserClick,
    handleSort,
    handlePageChange,
  };
};

export default useDashboardAdvertisersController;

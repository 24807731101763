import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useVerifySession } from '../../../hooks/query/Auth';
import { useEffect, useMemo } from 'react';
import HeaderNavigation from '../../../components/HeaderNavigation';
import { Helmet } from 'react-helmet';
import { useGetAdvertiserById } from '../../../hooks/query/Advertiser';
import { AdvertiserType } from '@prisma/client';
import clsx from 'clsx';
import { trpc } from '../../../utils/trpc';
import { useGetAdvertiserNavigationCounts } from '../../../hooks/query/Admin';

const DashboardAdvertiserDetailsOutlet: React.FC = () => {
  const params = useParams();
  const advertiserId = useMemo(
    () => (params.id !== undefined && /^\d+$/.test(params.id) ? parseInt(params.id) : null),
    [params.id]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: verifySession } = useVerifySession({
    onSuccess: async (data) => {
      if (data.userType !== 'admin') {
        await trpc.auth.logout.mutate();
        navigate('/');
      }
    },
    onError: () => {
      navigate('/');
    },
  });
  const { data: advertiser } = useGetAdvertiserById(advertiserId!, { enabled: !!advertiserId });
  const { data: counts } = useGetAdvertiserNavigationCounts(advertiserId!, { enabled: !!advertiserId });

  useEffect(() => {
    verifySession();
  }, [verifySession, location.pathname]);

  const navigation = useMemo(
    () => [
      {
        name: null,
        items: [
          {
            name: 'Infos/Einstellungen',
            path: `/dashboard/inserenten/${advertiserId}`,
          },
          {
            name: (
              <span>
                Stamm-/Rechnungsdaten
                {(advertiser?.needsBaseCheck || advertiser?.needsInvoiceCheck) && (
                  <span className="font-bold text-error">*</span>
                )}
              </span>
            ),
            path: `/dashboard/inserenten/${advertiserId}/daten`,
          },
          {
            name: 'Logo',
            path: `/dashboard/inserenten/${advertiserId}/logo`,
          },
          {
            name: 'Feeds',
            path: `/dashboard/inserenten/${advertiserId}/feeds`,
          },
          {
            name: `Rechnungen (${counts?.openInvoices ?? '?'} offen)`,
            path: `/dashboard/inserenten/${advertiserId}/rechnungen`,
          },
          {
            name: `Anzeigen (${counts?.uncheckedJobs ?? '?'} prüfen / ${counts?.onlineJobs ?? '?'} online)`,
            path: `/dashboard/inserenten/${advertiserId}/anzeigen`,
          },
        ],
      },
    ],
    [advertiserId, counts, advertiser?.needsBaseCheck, advertiser?.needsInvoiceCheck]
  );

  return (
    <>
      <Helmet>
        <title>
          {advertiser
            ? `[JM] Ins ${advertiser.id} – ${
                advertiser.type === AdvertiserType.COMPANY
                  ? advertiser.baseBusinessName
                  : `${advertiser.baseFirstName} ${advertiser.baseLastName}`
              }`
            : '[JM] Ins'}
        </title>
      </Helmet>
      <div className="min-h-full">
        <HeaderNavigation navigation={navigation} />
        <div
          className={clsx(
            !location.pathname.endsWith('/anzeigen') && !location.pathname.endsWith('/rechnungen') && 'p-8'
          )}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardAdvertiserDetailsOutlet;

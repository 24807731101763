import { useGetAllServices } from '../../../hooks/query/Service';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  JobQueryKeys,
  useActivateJob,
  useDeleteJob,
  useFlagJobAsChecked,
  useGetAllJobTags,
  useGetJobById,
  useUpdateJob,
} from '../../../hooks/query/Job';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import { useGetAdvertiserById, useGetLogo } from '../../../hooks/query/Advertiser';
import emojiRegex from 'emoji-regex';
import { WYSIWYGEditorRef } from '@jobmatic/web/components';
import { AdvertiserType, Geoservice, JobDeleteReason, JobState, OccupationType, RemoteOption } from '@prisma/client';
import { useDebounce } from 'react-use';
import { useSearchPlace } from '../../../hooks/query/Geo';
import { useQueryClient } from '@tanstack/react-query';
import { useDownloadInvoice, useGetInvoicesByAdvertiserId } from '../../../hooks/query/Invoice';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';

interface JobEditorLocation {
  id: string;
  city: string;
  region: string | null;
  coordinates: { lat: number; lng: number };
  bbox: { ne: { lat: number; lng: number }; sw: { lat: number; lng: number } };
  order: number;
}
const useDashboardJobDetailsMainController = (jobId: number) => {
  const queryClient = useQueryClient();
  const { data: job } = useGetJobById(jobId, {
    onSuccess: async (job) => {
      if (!job || title.length) return;
      resetState(job);
    },
  });
  const { data: advertiser } = useGetAdvertiserById(job?.advertiserId ?? 0, { enabled: !!job });
  const { data: logo } = useGetLogo(job?.advertiserId ?? 0, { enabled: !!job });
  const { data: services } = useGetAllServices();
  const { data: advertiserInvoices } = useGetInvoicesByAdvertiserId(job?.advertiserId ?? 0, { enabled: !!job });
  const { data: allTags, refetch: refetchAllTags } = useGetAllJobTags({
    onSuccess: (data) => {
      if (!job || !data || remoteTagsLoaded) return;
      setCurrentServiceTags(
        data
          .filter((t) =>
            t.services.map((s) => s.id).includes(baseServiceId ?? job.activatedForServiceId ?? job.bookingServiceId)
          )
          .filter((t) => job.tags.map((t) => t.id).includes(t.id))
          .map((t) => t.id.toString())
      );
      setOtherTags(
        data
          .filter(
            (t) =>
              !t.services.map((s) => s.id).includes(baseServiceId ?? job.activatedForServiceId ?? job.bookingServiceId)
          )
          .filter((t) => job.tags.map((t) => t.id).includes(t.id))
          .map((t) => t.id.toString())
      );
      setRemoteTagsLoaded(true);
    },
  });
  const { mutate: searchPlace } = useSearchPlace({
    onSuccess: (data) => {
      const newSearchResults = data
        .map((place) => {
          const region = place.context?.find((c) => c.type === 'region')?.name;
          return {
            id: place.id,
            city: place.shortName,
            region: region ?? null,
            coordinates: place.coordinates,
            bbox: place.bbox,
          };
        })
        .filter((place) => !locations.find((l) => l.id === place.id))
        .slice(0, 5);

      setSearchResults(newSearchResults);
      if (!hoveredSearchLocation && newSearchResults.length > 0) {
        setHoveredSearchLocation(newSearchResults[0].id);
      } else if (!newSearchResults.find((c) => c.id === hoveredSearchLocation)) {
        setHoveredSearchLocation(null);
      }
    },
  });
  const { mutate: updateJob, isLoading: isUpdating } = useUpdateJob({
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData([JobQueryKeys.GET_SINGLE, jobId], data);
        setRemoteTagsLoaded(false);
        resetState(data);
      } else queryClient.refetchQueries([JobQueryKeys.GET_SINGLE, jobId]);
      queryClient.invalidateQueries([JobQueryKeys.GET_ALL]);
      Helpers.scrollToTop();
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: deleteJob, isLoading: isDeleting } = useDeleteJob({
    onSuccess: () => {
      queryClient.refetchQueries([JobQueryKeys.GET_SINGLE, jobId]);
      queryClient.invalidateQueries([JobQueryKeys.GET_ALL]);
      Helpers.scrollToTop();
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: activateJob, isLoading: isActivating } = useActivateJob({
    onSuccess: () => {
      queryClient.refetchQueries([JobQueryKeys.GET_SINGLE, jobId]);
      queryClient.invalidateQueries([JobQueryKeys.GET_ALL]);
      Helpers.scrollToTop();
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: flagJobAsChecked, isLoading: isFlaggingAsChecked } = useFlagJobAsChecked({
    onSuccess: () => {
      queryClient.refetchQueries([JobQueryKeys.GET_SINGLE, jobId]);
      queryClient.invalidateQueries([JobQueryKeys.GET_ALL]);
      Helpers.scrollToTop();
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: downloadInvoice } = useDownloadInvoice({
    onSuccess: (data) => {
      if (!invoicePdfWindow) return;
      invoicePdfWindow.location = data.link;
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });

  const resetState = async (job: NonNullable<ReturnType<typeof useGetJobById>['data']>) => {
    setTitle(job.title);
    setDescriptionHtml(job.descriptionHtml);
    setDescriptionPlain(job.descriptionPlain);
    setDescriptionLength(job.descriptionPlain.length);
    setMinAge(job.minAge);
    setTargetUrl(job.targetUrl ?? '');
    setCountry(job.workCountry as keyof typeof Constants.COUNTRY_LIST);
    setRemote(job.remote);
    setLocations(
      job.workLocations.map((l) => ({
        id: l.geoservicePlaceId,
        coordinates: { lat: l.coordinates[1], lng: l.coordinates[0] },
        bbox: Helpers.parseDatabaseBboxToObjectBbox(l.bbox),
        city: l.name,
        region: l.region,
        order: l.order,
      }))
    );
    setOccupationTypes(job.occupationType);
    setShowEqualityNote(job.showEqualityNote);
    setHideAddress(!job.showAddress);
    setBaseServiceId(job.activatedForServiceId ?? job.bookingServiceId);
    setDurationWeeks((job.durationHours / 24 / 7) as 2 | 4);
    setInvoiceNote(job.invoiceNote ?? '');
    setIsHotjob(job.isHotjob);
    setPublishedServices(job.publishedInServices.map((s) => s.id));
    setHideFromJoblisting(job.hideFromJoblisting);
    setRedirectToTargetUrl(job.redirectToTargetUrl);
    setHideFromJobletter(job.hideFromJobletter);
    setIsFree(job.isFree);
    setIsTest(job.isTest);
    setDeleteReason(job.deleteReason);
    refetchAllTags();

    let retries = 0;
    while (!editorRef.current?.editor) {
      if (retries++ > 10) break;
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
    editorRef.current?.editor?.commands.setContent(job.descriptionHtml);
  };

  const editorRef = useRef<WYSIWYGEditorRef | null>(null);

  const [title, setTitle] = useState('');
  const [descriptionHtml, setDescriptionHtml] = useState('');
  const [descriptionPlain, setDescriptionPlain] = useState('');
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [minAge, setMinAge] = useState<number | null>(null);
  const [targetUrl, setTargetUrl] = useState('');
  const [country, setCountry] = useState<keyof typeof Constants.COUNTRY_LIST>('DE');
  const [remote, setRemote] = useState<RemoteOption>(RemoteOption.NOT_POSSIBLE);
  const [locations, setLocations] = useState<JobEditorLocation[]>([]);
  const [locationSearch, setLocationSearch] = useState('');
  const [searchResults, setSearchResults] = useState<Omit<JobEditorLocation, 'order'>[]>([]);
  const [hoveredSearchLocation, setHoveredSearchLocation] = useState<string | null>(null);
  const [occupationTypes, setOccupationTypes] = useState<OccupationType[]>([]);
  const [currentServiceTags, setCurrentServiceTags] = useState<string[]>([]);
  const [otherTags, setOtherTags] = useState<string[]>([]);
  const [showEqualityNote, setShowEqualityNote] = useState(false);
  const [displayedAddress, setDisplayedAddress] = useState<'job' | 'advertiser'>('job');
  const [hideAddress, setHideAddress] = useState(false);
  const [baseServiceId, setBaseServiceId] = useState<number | null>(null);
  const [durationWeeks, setDurationWeeks] = useState<2 | 4>(2);
  const [invoiceNote, setInvoiceNote] = useState('');
  const [isHotjob, setIsHotjob] = useState(false);
  const [publishedServices, setPublishedServices] = useState<number[]>([]);
  const [hideFromJoblisting, setHideFromJoblisting] = useState(false);
  const [redirectToTargetUrl, setRedirectToTargetUrl] = useState(false);
  const [hideFromJobletter, setHideFromJobletter] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [deleteReason, setDeleteReason] = useState<JobDeleteReason | null>(null);
  const [invoicePdfWindow, setInvoicePdfWindow] = useState<Window | null>(null);
  const [remoteTagsLoaded, setRemoteTagsLoaded] = useState(false);
  const [dontSendDeletionEmail, setDontSendDeletionEmail] = useState(false);

  const advertiserDataDiffers = useMemo(() => {
    if (!advertiser || !job) return false;
    return (
      job.businessName !==
        (advertiser.type === AdvertiserType.COMPANY
          ? advertiser.baseBusinessName
          : `${advertiser.baseFirstName} ${advertiser.baseLastName}`) ||
      job.businessAppendix !== (advertiser.type === AdvertiserType.COMPANY ? advertiser.baseBusinessAppendix : null) ||
      job.street !== advertiser.baseStreet ||
      job.zip !== advertiser.baseZip ||
      job.city !== advertiser.baseCity
    );
  }, [advertiser, job]);

  const minAgeService = useMemo(
    () =>
      (services
        ? publishedServices
            .map((serviceId) => services.find((service) => service.id === serviceId))
            ?.find((service) => service?.minAge !== null)
        : undefined) ?? null,
    [publishedServices, services]
  );

  const invoice = useMemo(
    () => advertiserInvoices?.invoices.find((invoice) => invoice.jobId === job?.id) ?? null,
    [advertiserInvoices, job]
  );

  useDebounce(
    () => {
      if (locationSearch.length >= 3) {
        searchPlace({
          query: locationSearch,
          filter: ['city'],
          filterCountry: country,
        });
      } else {
        setSearchResults([]);
      }
    },
    500,
    [locationSearch]
  );

  useEffect(() => {
    if (!locationSearch.length) {
      setSearchResults([]);
      setHoveredSearchLocation(null);
    }
  }, [locationSearch]);

  useEffect(() => {
    setCurrentServiceTags([]);
    setOtherTags([]);
  }, [baseServiceId]);

  useEffect(() => {
    setOtherTags([]);
  }, [publishedServices]);

  const handleTitleChange = (newTitle: string) => {
    const cleanedTitle = newTitle.replace(emojiRegex(), '');
    setTitle(cleanedTitle);
  };

  const handleCountryChange = (newCountry: keyof typeof Constants.COUNTRY_LIST) => {
    setLocationSearch('');
    setSearchResults([]);
    setHoveredSearchLocation(null);
    setLocations([]);
    setCountry(newCountry);
  };

  const handleRefreshJobAddress = async () => {
    if (!advertiser || !job) return;
    updateJob({
      id: job.id,
      businessName:
        advertiser.type === AdvertiserType.COMPANY
          ? advertiser.baseBusinessName ?? ''
          : `${advertiser.baseFirstName} ${advertiser.baseLastName}`,
      businessAppendix: advertiser.type === AdvertiserType.COMPANY ? advertiser.baseBusinessAppendix : null,
      street: advertiser.baseStreet,
      zip: advertiser.baseZip,
      city: advertiser.baseCity,
    });
  };

  const handleBaseServiceChange = (newBaseServiceId: number) => {
    setPublishedServices((prev) => [...prev.filter((serviceId) => serviceId !== baseServiceId), newBaseServiceId]);
    setBaseServiceId(newBaseServiceId);
  };

  const handleDeleteJob = async () => {
    if (!job) return;
    deleteJob({
      id: job.id,
      reason: deleteReason?.length ? deleteReason : undefined,
      skipEmail: dontSendDeletionEmail,
    });
  };

  const handleActivateJob = async (dontSendConfirmation = false) => {
    if (!job) return;
    activateJob({
      id: job.id,
      sendEmail: !dontSendConfirmation,
    });
  };

  const handleJobChecked = async () => {
    if (!job) return;
    flagJobAsChecked({
      id: job.id,
    });
  };

  const handleSaveJob = async () => {
    if (!job) return;

    if (redirectToTargetUrl && !targetUrl.trim().length) {
      alert('Wenn "kein Jobdetail" gesetzt ist, muss eine Ziel-URL angegeben werden.');
      return;
    }

    updateJob({
      id: job.id,
      title: title.trim(),
      descriptionHtml,
      descriptionPlain: descriptionPlain.trim().replace(emojiRegex(), ''),
      minAge: minAge ?? undefined,
      targetUrl: targetUrl.trim().length ? targetUrl.trim() : null,
      redirectToTargetUrl,
      country,
      remote: remote,
      locations:
        remote === RemoteOption.ONLY
          ? []
          : locations.map((l) => ({
              geoservice: Geoservice.MAPBOX,
              geoserviceId: l.id,
              name: l.city,
              region: l.region,
              coordinates: l.coordinates,
              bbox: l.bbox,
              order: l.order,
            })),
      occupationType: occupationTypes,
      tags: currentServiceTags.concat(otherTags).map((v) => parseInt(v, 10)),
      showEqualityNote,
      showAddress: !hideAddress,
      invoiceNote: ![JobState.ACTIVE, JobState.DELETED_BY_ADMIN, JobState.DELETED_BY_USER, JobState.EXPIRED].includes(
        job.state as any
      )
        ? invoiceNote.length > 0 && advertiser?.type === AdvertiserType.COMPANY
          ? invoiceNote
          : null
        : undefined,
      activatedForServiceId: baseServiceId ?? undefined,
      durationHours: durationWeeks * 7 * 24,
      isHotjob,
      publishedInServices: publishedServices,
      hideFromJoblisting,
      hideFromJobletter,
      isFree: job.createdWithFreeOption ? undefined : isFree,
      isTest,
    });
  };

  const handleDownloadInvoice = async () => {
    if (!invoice) return;
    setInvoicePdfWindow(window.open('about:blank', '_blank'));
    downloadInvoice({
      id: invoice.id,
    });
  };

  const resetLocationOrder = () => {
    setLocations((prev) => prev.map((l, idx) => ({ ...l, order: idx + 1 })));
  };

  const handleClickAdvertiserEmail = () => {
    // check if "ClipboardItem" is supported
    if (!ClipboardItem) {
      if (!navigator.clipboard) return;
      navigator.clipboard.writeText(`Bewerbungen bitte an ${advertiser?.email}.`);
      return;
    }
    navigator.clipboard.write([
      new ClipboardItem({
        'text/html': `Bewerbungen bitte an <a href="mailto:${advertiser?.email}">${advertiser?.email}</a>.`,
      }),
    ]);
  };

  const handleClickMixedSpelling = () => {
    // set title to capitalized version of title (THIS IS A TITLE -> This Is A Title)
    setTitle(
      title
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    );
  };

  return {
    job,
    advertiser,
    logo,
    services: services || [],
    allTags: allTags || [],
    invoice,
    isUpdating,
    isDeleting,
    isActivating,
    isFlaggingAsChecked,
    editorRef,
    minAgeService,
    advertiserDataDiffers,
    baseServiceId,
    setBaseServiceId: handleBaseServiceChange,
    title,
    setTitle: handleTitleChange,
    descriptionHtml,
    setDescriptionHtml,
    descriptionPlain,
    setDescriptionPlain,
    descriptionLength,
    setDescriptionLength,
    minAge,
    setMinAge,
    targetUrl,
    setTargetUrl,
    country,
    setCountry: handleCountryChange,
    remote,
    setRemote,
    locations,
    setLocations,
    locationSearch,
    setLocationSearch,
    searchResults,
    setSearchResults,
    hoveredSearchLocation,
    setHoveredSearchLocation,
    occupationTypes,
    setOccupationTypes,
    currentServiceTags,
    setCurrentServiceTags,
    otherTags,
    setOtherTags,
    showEqualityNote,
    setShowEqualityNote,
    displayedAddress,
    setDisplayedAddress,
    hideAddress,
    setHideAddress,
    durationWeeks,
    setDurationWeeks,
    isHotjob,
    setIsHotjob,
    publishedServices,
    setPublishedServices,
    invoiceNote,
    setInvoiceNote,
    hideFromJoblisting,
    setHideFromJoblisting,
    redirectToTargetUrl,
    setRedirectToTargetUrl,
    hideFromJobletter,
    setHideFromJobletter,
    isFree,
    setIsFree,
    isTest,
    setIsTest,
    deleteReason,
    setDeleteReason,
    dontSendDeletionEmail,
    setDontSendDeletionEmail,
    handleRefreshJobAddress,
    handleDeleteJob,
    handleActivateJob,
    handleJobChecked,
    handleSaveJob,
    handleDownloadInvoice,
    handleClickAdvertiserEmail,
    handleClickMixedSpelling,
    resetLocationOrder,
  };
};

export default useDashboardJobDetailsMainController;

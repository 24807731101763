import { useQuery } from '@tanstack/react-query';
import { UseQueryTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';

export enum ServiceQueryKeys {
  GET_ALL = 'SERVICE_GET_ALL',
}

export const useGetAllServices = (options?: UseQueryTypeHelper<typeof trpc.service.getAll.query>) =>
  useQuery([ServiceQueryKeys.GET_ALL], () => trpc.service.getAll.query(), options);

export const useGetServiceById = (
  serviceId: number,
  options?: UseQueryTypeHelper<typeof trpc.service.getById.query | (() => null)>
) => useQuery([ServiceQueryKeys.GET_ALL, serviceId], () => trpc.service.getById.query({ id: serviceId }), options);

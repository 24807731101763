import { useMutation } from '@tanstack/react-query';
import { UseMutationTypeHelper } from '@jobmatic/shared/types';
import { MapboxGeoservice, MaptilerGeoservice, PlaceType } from '@jobmatic/shared/geoservice';
import { Constants } from '@jobmatic/shared/utils';

const geo = new MapboxGeoservice();
export const useSearchPlace = (
  options?: UseMutationTypeHelper<
    (typeof MaptilerGeoservice)['prototype']['search'],
    {
      query: string;
      proximity?: { lat: number; lon: number };
      filter?: PlaceType[];
      filterCountry?: keyof typeof Constants.COUNTRY_LIST;
    }
  >
) =>
  useMutation(
    (data) =>
      geo.search(data.query, {
        proximity: data.proximity,
        filter: data.filter,
        filterCountry: data.filterCountry,
      }),
    options
  );

import useDashboardCouponsController, { COUPON_COLUMNS, CouponFilter } from '../../../controllers/Dashboard/Coupons';
import dayjs from 'dayjs';
import { Constants } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import Button from '../../../components/Button';
import { Helmet } from 'react-helmet';
import SelectBox from '../../../components/SelectBox';
import Input from '../../../components/Input';
import Checkbox from '../../../components/Checkbox';
import { CouponType } from '@prisma/client';

interface DashboardCouponsMainViewProps {
  hideColumns?: (keyof typeof COUPON_COLUMNS)[];
  defaultOrderColumn?: keyof typeof COUPON_COLUMNS;
  filter?: CouponFilter;
  filterOperator?: 'AND' | 'OR';
}

const DashboardCouponsMainView: React.FC<DashboardCouponsMainViewProps> = ({
  defaultOrderColumn,
  hideColumns,
  filter,
  filterOperator,
}) => {
  const controller = useDashboardCouponsController(
    { column: defaultOrderColumn ?? 'code', direction: 'desc' },
    filter,
    filterOperator
  );

  return (
    <>
      <Helmet>
        <title>[JM] Gutschein-Codes</title>
      </Helmet>
      <div className="py-2 bg-light grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-8 gap-4 px-4 lg:px-16 xl:px-32">
        <SelectBox
          value={controller.couponType === null ? '' : controller.couponType}
          onChange={(e) => controller.setCouponType(e.target.value as CouponType)}
          options={[
            { value: CouponType.SINGLE, label: 'Einfachrabatt' },
            { value: CouponType.MULTI, label: 'Mehrfachrabatt' },
          ]}
          placeholder="Rabattart*"
          className="!h-12 col-span-1"
        />
        <SelectBox
          value={controller.serviceId === null ? '' : controller.serviceId.toString()}
          onChange={(e) => controller.setServiceId(parseInt(e.target.value))}
          options={controller.services.map((service) => ({
            value: service.id.toString(),
            label: service.name,
          }))}
          placeholder="Service*"
          className="!h-12 col-span-3 sm:col-span-2"
        />
        <SelectBox
          value={controller.discount === null ? '' : controller.discount.toString()}
          onChange={(e) => controller.setDiscount(parseInt(e.target.value))}
          options={Array.from({ length: 21 }, (_, i) => i * 5).map((num) => ({
            value: num.toString(),
            label: `${num}%`,
          }))}
          placeholder="Rabatt*"
          className="!h-12 col-span-3 sm:col-span-2 lg:col-span-1"
        />
        <Input
          placeholder="Präfix-"
          value={controller.prefix}
          onChange={controller.setPrefix}
          className={clsx('!h-12 col-span-2 sm:col-span-1', controller.couponType === CouponType.MULTI && 'hidden')}
          disabled={controller.couponType === CouponType.MULTI}
        />
        <Input
          placeholder="Anzahl*"
          value={controller.count}
          onChange={controller.setCount}
          type="number"
          min={1}
          className={clsx('!h-12 col-span-2 sm:col-span-1', controller.couponType === CouponType.MULTI && 'hidden')}
          disabled={controller.couponType === CouponType.MULTI}
        />
        <Input
          placeholder="Code*"
          value={controller.customCode}
          onChange={controller.setCustomCode}
          className={clsx('!h-12 col-span-4 sm:col-span-2', controller.couponType !== CouponType.MULTI && 'hidden')}
          disabled={controller.couponType !== CouponType.MULTI}
        />
        <Checkbox
          label="CSV"
          checked={controller.generateCsv}
          onChange={controller.setGenerateCsv}
          disabled={controller.couponType === CouponType.MULTI}
        />
        <Button
          onClick={controller.handleGenerate}
          disabled={
            controller.serviceId === null ||
            controller.discount === null ||
            controller.count.length < 1 ||
            controller.isGenerating
          }
          className={clsx('!h-12 col-span-3 sm:col-span-4 lg:col-span-1', controller.isGenerating && '!cursor-wait')}
          title="Generieren"
        />
      </div>
      <table className="min-w-full text-left [&>*>tr>*]:px-1 [&>*>tr>*]:py-2 overflow-x-scroll">
        <thead className="lowercase bg-light text-2xs h-12">
          <tr>
            {Object.entries(COUPON_COLUMNS).map(([key, value]) => {
              if (hideColumns && hideColumns.includes(key as keyof typeof COUPON_COLUMNS)) {
                return null;
              }
              return (
                <th
                  key={key}
                  className={clsx(
                    'border-x border-x-border border-dotted',
                    controller.currentSort === key ? 'font-bold' : 'font-normal',
                    value.sortable && '!cursor-pointer hover:opacity-50'
                  )}
                  onClick={
                    value.sortable ? () => controller.handleSort(key as keyof typeof COUPON_COLUMNS) : undefined
                  }>
                  {value.name}
                </th>
              );
            })}
            <th className={"border-x border-x-border border-dotted'"}>{''}</th>
          </tr>
        </thead>
        <tbody className="text-xs">
          {controller.coupons.map((coupon, idx) => (
            <tr key={coupon.code}>
              {Object.entries(COUPON_COLUMNS).map(([key]) => {
                if (hideColumns && hideColumns.includes(key as keyof typeof COUPON_COLUMNS)) {
                  return null;
                }

                let data = '';
                let extraClassNames = coupon.disabled ? 'line-through ' : '';
                let onClick: (() => void) | undefined = undefined;
                switch (key) {
                  case 'type':
                    data = coupon.type === CouponType.SINGLE ? 'Einfach' : 'Mehrfach';
                    break;
                  case 'count':
                    data = ((controller.page - 1) * Constants.ADMIN_PAGE_ENTRY_LIMIT + idx + 1).toString();
                    break;
                  case 'code':
                    data = coupon.code;
                    onClick = () => controller.handleCouponClick(coupon.code);
                    break;
                  case 'discountPercentage':
                    data = coupon.discountPercentage.toString();
                    extraClassNames += 'text-right';
                    break;
                  case 'service':
                    data = coupon.validForServiceName;
                    break;
                  case 'createdAt':
                    data = dayjs(coupon.createdAt).format('DD.MM.YYYY');
                    break;
                  case 'redeemedAt':
                    data = coupon.redeemedAt ? dayjs(coupon.redeemedAt).format('DD.MM.YYYY') : '';
                    if (coupon.type === CouponType.MULTI) {
                      data = '(mehrfach)';
                      extraClassNames += 'italic';
                    }
                    break;
                  case 'redeemedBy':
                    data = coupon.redeemedByAdvertiserName ?? '';
                    onClick = coupon.redeemedByAdvertiserId
                      ? () => controller.handleAdvertiserClick(coupon.code)
                      : undefined;
                    if (coupon.type === CouponType.MULTI) {
                      data = '(mehrfach)';
                      extraClassNames += 'italic';
                    }
                    break;
                  default:
                    data = key;
                }
                return (
                  <td
                    key={key}
                    className={clsx(
                      'border border-border',
                      key === 'option' && 'text-center',
                      onClick && 'hover:bg-gray cursor-pointer',
                      extraClassNames
                    )}
                    onClick={onClick}>
                    {data}
                  </td>
                );
              })}
              {(coupon.type === CouponType.SINGLE && !coupon.redeemedByAdvertiserId) ||
              (coupon.type === CouponType.MULTI && !coupon.disabled) ? (
                <td
                  className={clsx(
                    'border border-border',
                    !controller.deletingCoupons.includes(coupon.code) ? 'hover:bg-gray cursor-pointer' : '!cursor-wait'
                  )}
                  onClick={() => controller.handleDeleteCouponClick(coupon.code, coupon.validForServiceId)}>
                  löschen
                </td>
              ) : (
                <td className={'border border-border'}></td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row gap-2 w-full justify-center my-4">
        <Button onClick={() => controller.handlePageChange(1)} secondary className="!font-normal" title="|<" />
        <Button
          onClick={() => controller.handlePageChange(controller.page - 1)}
          secondary
          className="!font-normal"
          title="<"
        />
        {/* Show buttons for the current page (controller.page) in the center as well as the 3 pages before and after */}
        {Array.from({ length: 7 })
          .map((_, idx) => controller.page - 3 + idx)
          .filter((page) => page > 0 && page <= controller.pages)
          .map((page) => (
            <Button
              key={page}
              onClick={() => controller.handlePageChange(page)}
              secondary={page !== controller.page}
              className="!font-normal"
              title={page.toString()}
            />
          ))}
        <Button
          onClick={() => controller.handlePageChange(controller.page + 1)}
          secondary
          className="!font-normal"
          title=">"
        />
        <Button
          onClick={() => controller.handlePageChange(controller.pages)}
          secondary
          className="!font-normal"
          title=">|"
        />
      </div>
    </>
  );
};

export default DashboardCouponsMainView;

import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import SelectBox from '../../../components/SelectBox';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import InputGroup from '../../../components/InputGroup';
import useDashboardJobDetailsMainController from '../../../controllers/Dashboard/JobDetails/Main';
import Input from '../../../components/Input';
import { TagContainer, WYSIWYGEditor } from '@jobmatic/web/components';
import { useMemo } from 'react';
import { AdvertiserType, JobDeleteReason, JobState, OccupationType, RemoteOption } from '@prisma/client';

const DashboardJobDetailsMainView: React.FC = () => {
  const params = useParams();
  const controller = useDashboardJobDetailsMainController(parseInt(params.id || '-1'));

  const remoteOptions = useMemo(() => {
    return Object.entries(
      Constants.REMOTE_OPTIONS(Constants.COUNTRY_LIST[controller.country], controller.country)
    ).reduce((acc, [key, value]) => {
      acc[key as RemoteOption] = value.creation;
      return acc;
    }, {} as Record<RemoteOption, string>);
  }, [controller.country]);

  if (!controller.job) return null; // TODO: Loading state

  return (
    <div className="lg:grid grid-cols-2 text-sm">
      <div className="flex flex-col gap-4 lg:pr-8 lg:border-r border-r-border">
        <InputGroup
          label={`Anzeigentitel (max. ${(Constants.MAX_JOB_TITLE_LENGTH - controller.title.length)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} Zeichen)`}
          rightLabel="mixed spelling"
          onRightLabelClick={controller.handleClickMixedSpelling}>
          <Input value={controller.title} onChange={controller.setTitle} />
        </InputGroup>
        <InputGroup
          label={`Jobbeschreibung (max. ${(Constants.MAX_JOB_DESCRIPTION_LENGTH - controller.descriptionLength)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')} Zeichen)`}>
          <WYSIWYGEditor
            ref={controller.editorRef}
            value={controller.descriptionHtml}
            onChange={(text, { characters, plainText }) => {
              controller.setDescriptionHtml(text);
              controller.setDescriptionPlain(plainText);
              controller.setDescriptionLength(characters);
            }}
            maxLength={Constants.MAX_JOB_DESCRIPTION_LENGTH}
            showUrlTooltips
          />
        </InputGroup>
        {!!controller.minAgeService?.minAge && (
          <InputGroup label="Mindestalter">
            <SelectBox
              value={controller.minAge?.toString() ?? '18'}
              onChange={(e) => controller.setMinAge(parseInt(e.target.value))}
              options={Array.from(
                { length: 18 - controller.minAgeService.minAge + 1 },
                (_, i) => i + controller.minAgeService!.minAge!
              ).map((v) => ({ value: v.toString(), label: `${v} Jahre` }))}
            />
          </InputGroup>
        )}
        <InputGroup label="Ziel-URL" className="border-t border-t-border mt-4 pt-6">
          <Input value={controller.targetUrl} onChange={controller.setTargetUrl} />
        </InputGroup>
        <InputGroup label="Land" className="border-t border-t-border mt-4 pt-6">
          <SelectBox
            value={controller.country}
            onChange={(e) => controller.setCountry(e.target.value as keyof typeof Constants.COUNTRY_LIST)}
            options={Object.entries(Constants.COUNTRY_LIST).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </InputGroup>
        <InputGroup label="Remote / Home Office">
          <SelectBox
            value={controller.remote}
            onChange={(e) => controller.setRemote(e.target.value as RemoteOption)}
            options={Object.entries(remoteOptions).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </InputGroup>
        <InputGroup label="Stadt / Städte">
          <TagContainer
            tags={controller.locations
              .map((location) => ({ ...location, label: location.city }))
              // sort by location.order number, so that order = 0 is first
              .sort((a, b) => a.order - b.order)}
            onToggle={(id) => {
              const location = controller.locations.find((v) => v.id === id);
              if (location) {
                controller.setLocations((prev) => prev.filter((v) => v.id !== id));
                controller.resetLocationOrder();
              }
            }}
            onChangeSearch={controller.locations.length < 50 ? (v) => controller.setLocationSearch(v) : undefined}
            searchValue={controller.locations.length < 50 ? controller.locationSearch : undefined}
            additionalSearchProps={{
              onKeyDown: (e) => {
                if (e.keyCode === 40) {
                  e.preventDefault();
                  const currentIdx = controller.searchResults.findIndex(
                    (v) => v.id === controller.hoveredSearchLocation
                  );
                  if (currentIdx < controller.searchResults.length - 1) {
                    controller.setHoveredSearchLocation(controller.searchResults[currentIdx + 1].id);
                  } else {
                    controller.setHoveredSearchLocation(controller.searchResults[0].id);
                  }
                } else if (e.keyCode === 38) {
                  e.preventDefault();
                  const currentIdx = controller.searchResults.findIndex(
                    (v) => v.id === controller.hoveredSearchLocation
                  );
                  if (currentIdx > 0) {
                    controller.setHoveredSearchLocation(controller.searchResults[currentIdx - 1].id);
                  } else {
                    controller.setHoveredSearchLocation(
                      controller.searchResults[controller.searchResults.length - 1].id
                    );
                  }
                } else if (e.keyCode === 13) {
                  e.preventDefault();
                  if (controller.hoveredSearchLocation !== null) {
                    controller.setLocationSearch('');
                    controller.setHoveredSearchLocation(null);
                    controller.setLocations((prev) => [
                      ...prev,
                      {
                        ...controller.searchResults.find((v) => v.id === controller.hoveredSearchLocation)!,
                        order: prev.length + 1,
                      },
                    ]);
                  }
                } else if (e.keyCode === 8) {
                  if (controller.locationSearch.length === 0 && controller.locations.length > 0) {
                    controller.setLocations((prev) => prev.slice(0, controller.locations.length - 1));
                  }
                } else if (e.keyCode === 27) {
                  e.preventDefault();
                  controller.setLocationSearch('');
                }
              },
            }}
            append={
              controller.searchResults.length > 0 ? (
                <div className="absolute z-10 bg-white left-0 right-0 shadow-md rounded-md overflow-hidden">
                  {controller.searchResults.map((v) => (
                    <div
                      key={v.id}
                      className={clsx(
                        'px-4 py-2 cursor-pointer',
                        controller.hoveredSearchLocation === v.id && 'bg-light'
                      )}
                      onMouseEnter={() => controller.setHoveredSearchLocation(v.id)}
                      onMouseLeave={() => controller.setHoveredSearchLocation(null)}
                      onClick={() => {
                        controller.setLocationSearch('');
                        controller.setHoveredSearchLocation(null);
                        controller.setLocations((prev) => [...prev, { ...v, order: prev.length + 1 }]);
                      }}>
                      {v.city}
                      {!!v.region?.length && <span className="opacity-30"> ({v.region})</span>}
                    </div>
                  ))}
                </div>
              ) : undefined
            }
          />
        </InputGroup>
        <InputGroup label="Beschäftigungsverhältnis" className="border-t border-t-border mt-4 pt-6">
          <TagContainer
            tags={Object.entries(Constants.OCCUPATION_TYPE_OPTIONS).map(([key, value]) => ({
              id: key,
              label: value,
              selected: controller.occupationTypes.includes(key as OccupationType),
            }))}
            onToggle={(id) =>
              controller.setOccupationTypes((prev) =>
                prev.includes(id as OccupationType) ? prev.filter((v) => v !== id) : [...prev, id as OccupationType]
              )
            }
          />
        </InputGroup>
      </div>
      <div className="flex flex-col gap-4 lg:pl-8 mt-8 lg:mt-0">
        <InputGroup
          label={`Tags ${controller.services.find((service) => service.id === controller.baseServiceId)?.name}`}>
          <TagContainer
            tags={controller.allTags
              .filter((tag) => tag.services.map((service) => service.id).includes(controller.baseServiceId ?? -1))
              .map((tag) => ({
                id: tag.id.toString(),
                label: tag.tag,
                selected: controller.currentServiceTags.includes(tag.id.toString()),
              }))}
            onToggle={(id) =>
              controller.setCurrentServiceTags((prev) =>
                prev.includes(id) ? prev.filter((v) => v !== id) : [...prev, id]
              )
            }
          />
        </InputGroup>
        <InputGroup label="Tags andere">
          <TagContainer
            tags={controller.allTags
              .filter(
                (tag) =>
                  !tag.services.map((service) => service.id).includes(controller.baseServiceId ?? -1) &&
                  tag.services.map((service) => service.id).some((id) => controller.publishedServices.includes(id))
              )
              .map((tag) => ({
                id: tag.id.toString(),
                label: tag.tag,
                selected: controller.otherTags.includes(tag.id.toString()),
              }))}
            onToggle={(id) =>
              controller.setOtherTags((prev) => (prev.includes(id) ? prev.filter((v) => v !== id) : [...prev, id]))
            }
          />
        </InputGroup>
        <InputGroup label="Gleichstellungszusatz" className="border-t border-t-border mt-4 pt-6">
          <Checkbox checked={controller.showEqualityNote} onChange={controller.setShowEqualityNote} label="anzeigen" />
        </InputGroup>
        <div className="border-t border-t-border mt-4 pt-6 flex flex-col lg:flex-row gap-4">
          <div className="w-40 h-40 border border-border p-4">
            {controller.logo ? (
              <img
                src={`data:${controller.logo.mimeType};base64,${controller.logo.base64}`}
                alt="Logo"
                className="w-full h-full object-contain cursor-pointer"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-light cursor-pointer">
                <p className="text-dark text-center p-4">Kein Logo vorhanden</p>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <span
                className={clsx('cursor-pointer', controller.displayedAddress === 'job' && 'font-bold')}
                onClick={() => controller.setDisplayedAddress('job')}>
                Anzeigendaten
              </span>
              <span className="mx-2">|</span>
              <span
                className={clsx('cursor-pointer', controller.displayedAddress === 'advertiser' && 'font-bold')}
                onClick={() => controller.setDisplayedAddress('advertiser')}>
                Stammdaten
                {controller.advertiserDataDiffers && <span className="text-error text-sm">*</span>}
              </span>
            </div>
            <div>
              {controller.displayedAddress === 'job' ? (
                <>
                  <div>
                    {
                      // "businessName" will be populated with first & last name on DB level if advertiser is a private person
                      controller.job.businessName
                    }
                    {!!controller.job.businessAppendix?.length && (
                      <>
                        <br />
                        {controller.job.businessAppendix}
                      </>
                    )}
                    <span className={clsx('transition', controller.hideAddress ? 'opacity-30' : 'opacity-100')}>
                      <br />
                      {controller.job.street}
                      <br />
                      {controller.job.zip} {controller.job.city}
                    </span>
                  </div>
                  <Checkbox
                    checked={controller.hideAddress}
                    onChange={controller.setHideAddress}
                    label="Anschrift nicht anzeigen"
                    className="mt-4"
                  />
                  <div className="mt-4 cursor-pointer" onClick={controller.handleClickAdvertiserEmail}>
                    {controller.advertiser?.email}
                  </div>
                </>
              ) : (
                <>
                  {controller.advertiser?.type === AdvertiserType.COMPANY
                    ? controller.advertiser?.baseBusinessName
                    : `${controller.advertiser?.baseFirstName} ${controller.advertiser?.baseLastName}`}
                  {controller.advertiser?.type === AdvertiserType.COMPANY &&
                    !!controller.advertiser.baseBusinessAppendix?.length && (
                      <>
                        <br />
                        {controller.advertiser?.baseBusinessAppendix}
                      </>
                    )}
                  <br />
                  {controller.advertiser?.baseStreet}
                  <br />
                  {controller.advertiser?.baseZip} {controller.advertiser?.baseCity}
                </>
              )}
            </div>
            {controller.advertiserDataDiffers && (
              <div className="underline cursor-pointer" onClick={controller.handleRefreshJobAddress}>
                Daten aktualisieren
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 p-4 bg-light flex flex-col gap-4">
          <InputGroup
            label={`Schaltung auf ${
              controller.services.find((service) => service.id === controller.job?.bookingServiceId)?.name
            }`}>
            <SelectBox
              value={(controller.baseServiceId ?? 1).toString()}
              onChange={(e) => controller.setBaseServiceId(parseInt(e.target.value))}
              options={controller.services.map((service) => ({
                value: service.id.toString(),
                label: service.name,
              }))}
            />
          </InputGroup>
          <InputGroup label="Laufzeit">
            <SelectBox
              value={controller.durationWeeks.toString()}
              onChange={(e) => controller.setDurationWeeks(parseInt(e.target.value) as 2 | 4)}
              options={[
                { value: '2', label: '2 Wochen' },
                { value: '4', label: '4 Wochen' },
              ]}
            />
          </InputGroup>
          <InputGroup label="Anzeigenoptionen">
            <Checkbox
              checked={controller.isHotjob}
              onChange={controller.setIsHotjob}
              label={
                controller.services.find((service) => service.id === controller.baseServiceId)?.hotJobName ?? 'HotJob'
              }
            />
          </InputGroup>
          <InputGroup label="Verteilung" className="border-t border-t-border mt-4 pt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {controller.services.map((service) => (
                <Checkbox
                  checked={controller.publishedServices.includes(service.id)}
                  onChange={(checked) => {
                    if (checked) {
                      controller.setPublishedServices([...controller.publishedServices, service.id]);
                    } else {
                      controller.setPublishedServices(controller.publishedServices.filter((id) => id !== service.id));
                    }
                  }}
                  label={service.name}
                  disabled={service.id === (controller.baseServiceId ?? controller.job?.bookingServiceId)}
                />
              ))}
            </div>
          </InputGroup>
          <InputGroup label="Optionen" className="border-t border-t-border mt-4 pt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              <Checkbox
                checked={controller.hideFromJoblisting}
                onChange={controller.setHideFromJoblisting}
                label="nicht im Joblisting"
              />
              <Checkbox
                checked={controller.redirectToTargetUrl}
                onChange={controller.setRedirectToTargetUrl}
                label="kein Jobdetail"
              />
              <Checkbox
                checked={controller.hideFromJobletter}
                onChange={controller.setHideFromJobletter}
                label="nicht im Jobletter"
              />
              <Checkbox
                checked={controller.isFree || controller.job.createdWithFreeOption}
                onChange={
                  controller.invoice === null
                    ? controller.setIsFree
                    : () => {
                        /* noop */
                      }
                }
                label="ohne Berechnung"
                disabled={controller.invoice !== null || controller.job.createdWithFreeOption}
              />
              <Checkbox checked={controller.isTest} onChange={controller.setIsTest} label="Test-Job" />
              <Checkbox
                checked={controller.job.importId !== null}
                disabled
                onChange={() => {
                  /* noop */
                }}
                label={
                  controller.job.importId !== null ? `Importiert (anzid: ${controller.job.importId})` : 'Importiert'
                }
              />
            </div>
          </InputGroup>
          <div className="border-t border-t-border mt-4 pt-6">
            {controller.job && (
              <div className="grid grid-cols-3 lg:grid-cols-4">
                {[
                  {
                    title: 'Schaltung',
                    description: (
                      <div>
                        {dayjs(controller.job.createdAt).format('DD.MM.YYYY')}{' '}
                        <span className="opacity-40">{dayjs(controller.job.createdAt).format('HH:mm')} Uhr</span>
                      </div>
                    ),
                  },
                  {
                    title: 'Aktivierung',
                    description: controller.job.activatedAt ? (
                      <div>
                        {dayjs(controller.job.activatedAt).format('DD.MM.YYYY')}{' '}
                        <span className="opacity-40">{dayjs(controller.job.activatedAt).format('HH:mm')} Uhr</span>
                      </div>
                    ) : (
                      ''
                    ),
                  },
                  {
                    title: 'Online bis',
                    description: controller.job.activatedAt ? (
                      <div>
                        {dayjs(controller.job.activatedAt)
                          .add(controller.job.durationHours, 'hour')
                          .format('DD.MM.YYYY')}{' '}
                        <span className="opacity-40">
                          {dayjs(controller.job.activatedAt).add(controller.job.durationHours, 'hour').format('HH:mm')}{' '}
                          Uhr
                        </span>
                      </div>
                    ) : (
                      ''
                    ),
                  },
                  {
                    title: 'Views',
                    description: `${controller.job.realViews} / ${controller.job.fakeViews}`,
                    className: 'my-2',
                  },
                  {
                    title: 'Wert (netto)',
                    description: `${Helpers.parsePrice(controller.isFree ? 0 : controller.job.finalNetPrice)} €`,
                  },
                  {
                    title: 'Kundenrabatt',
                    description:
                      controller.job.finalCustomerDiscountPercentage > 0
                        ? `${controller.isFree ? 0 : controller.job.finalCustomerDiscountPercentage} %`
                        : ``,
                  },
                  {
                    title: 'Gutschein',
                    description: controller.job.couponCode ? controller.job.couponCode : '',
                  },
                  {
                    title: 'Zahlungsweise',
                    description: controller.isFree ? (
                      'n/a'
                    ) : (
                      <span>
                        {Constants.PAYMENT_METHOD_OPTIONS[controller.job.paymentMethod]}
                        {controller.invoice ? (
                          <>
                            {' ('}
                            <span className="underline cursor-pointer" onClick={controller.handleDownloadInvoice}>
                              Rechnung {controller.invoice?.invoiceNumber}
                            </span>
                            {')'}
                          </>
                        ) : null}
                      </span>
                    ),
                  },
                  {
                    title: 'Status',
                    description: Helpers.parseJobState(controller.job.state),
                    className: 'my-2',
                  },
                ].map((item) => (
                  <>
                    <div className={item.className}>{item.title}</div>
                    <div className={clsx('col-span-2 lg:col-span-3', item.className)}>{item.description}</div>
                  </>
                ))}
              </div>
            )}
            <InputGroup label="Rechnungsvermerk">
              <Input
                disabled={
                  [JobState.ACTIVE, JobState.DELETED_BY_ADMIN, JobState.DELETED_BY_USER, JobState.EXPIRED].includes(
                    controller.job.state as any
                  ) || controller.job.isFree
                }
                value={controller.invoiceNote}
                onChange={controller.setInvoiceNote}
                maxLength={60}
              />
            </InputGroup>
            <div className="border-t border-t-border mt-6 pt-6 flex flex-col gap-4">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <SelectBox
                  value={controller.deleteReason ?? ''}
                  onChange={
                    [JobState.DELETED_BY_ADMIN, JobState.DELETED_BY_USER, JobState.EXPIRED].includes(
                      controller.job.state as any
                    )
                      ? () => {
                          /* noop */
                        }
                      : (e) => controller.setDeleteReason(e.target.value as JobDeleteReason)
                  }
                  options={[
                    { value: '', label: 'kein Löschgrund' },
                    ...Object.entries(Constants.JOB_DELETE_REASON_OPTIONS).map(([key, val]) => ({
                      value: key,
                      label: val.admin,
                    })),
                  ]}
                  className="md:col-span-2"
                />
                <Checkbox
                  checked={controller.dontSendDeletionEmail}
                  onChange={controller.setDontSendDeletionEmail}
                  label="ohne Mail"
                />
                <Button
                  onClick={controller.handleDeleteJob}
                  disabled={
                    controller.isDeleting ||
                    (!controller.deleteReason && !controller.dontSendDeletionEmail) ||
                    [JobState.DELETED_BY_ADMIN, JobState.DELETED_BY_USER, JobState.EXPIRED].includes(
                      controller.job.state as any
                    )
                  }
                  className={clsx(controller.isDeleting && '!cursor-wait')}
                  title="löschen"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Button
                  onClick={() => controller.handleActivateJob(true)}
                  disabled={controller.isActivating || controller.job.state !== JobState.UNCHECKED}
                  title="aktivieren ohne Bestätigung"
                  className={clsx(controller.isActivating && '!cursor-wait')}
                />
                <Button
                  onClick={() => controller.handleActivateJob(false)}
                  disabled={controller.isActivating || controller.job.state !== JobState.UNCHECKED}
                  title="aktivieren"
                  className={clsx(controller.isActivating && '!cursor-wait')}
                />
              </div>
              <Button
                onClick={controller.handleJobChecked}
                disabled={
                  controller.isFlaggingAsChecked ||
                  !(controller.job.state === JobState.ACTIVE_UNCHECKED || controller.job.isEdited)
                }
                title="geprüft"
                className={clsx(controller.isFlaggingAsChecked && '!cursor-wait')}
              />
              <Button
                onClick={controller.handleSaveJob}
                title="speichern"
                disabled={controller.isUpdating}
                className={clsx(controller.isUpdating && '!cursor-wait')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardJobDetailsMainView;

import { useQuery, useMutation } from '@tanstack/react-query';
import { UseQueryTypeHelper, UseMutationTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';

export enum InvoiceQueryKeys {
  GET_ALL = 'INVOICE_GET_ALL',
  GET_SINGLE = 'INVOICE_GET_SINGLE',
  GET_BY_ADVERTISER_ID = 'INVOICE_GET_BY_ADVERTISER_ID',
}

export const useGetAllInvoices = (
  data: Parameters<typeof trpc.invoice.getAll.query>[0],
  options?: UseQueryTypeHelper<typeof trpc.invoice.getAll.query>
) => useQuery([InvoiceQueryKeys.GET_ALL, data], () => trpc.invoice.getAll.query(data), options);

export const useGetInvoiceById = (
  invoiceId: number,
  options?: UseQueryTypeHelper<typeof trpc.invoice.getById.query | (() => null)>
) => useQuery([InvoiceQueryKeys.GET_SINGLE, invoiceId], () => trpc.invoice.getById.query({ id: invoiceId }), options);

export const useGetInvoicesByAdvertiserId = (
  advertiserId: number,
  options?: UseQueryTypeHelper<typeof trpc.invoice.getByAdvertiserId.query | (() => null)>
) =>
  useQuery(
    [InvoiceQueryKeys.GET_BY_ADVERTISER_ID, advertiserId],
    () => trpc.invoice.getByAdvertiserId.query({ advertiserId }),
    options
  );

export const useDownloadInvoice = (
  options?: UseMutationTypeHelper<
    typeof trpc.invoice.downloadPdf.mutate,
    Parameters<typeof trpc.invoice.downloadPdf.mutate>[0]
  >
) => useMutation((data) => trpc.invoice.downloadPdf.mutate(data), options);

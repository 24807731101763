import clsx from 'clsx';

interface InputGroupProps {
  label?: string;
  rightLabel?: string;
  onRightLabelClick?: () => void;
  helper?: string | React.ReactNode;
  children: React.ReactNode;
  className?: string;
}
const InputGroup: React.FC<InputGroupProps> = ({
  label,
  rightLabel,
  onRightLabelClick,
  helper,
  children,
  className,
}) => {
  return (
    <div className={clsx('flex flex-col', className)}>
      {(label || rightLabel) && (
        <div className="flex justify-between items-center mb-1">
          {label && <label className={clsx('text-[#ccc] text-xs')}>{label}</label>}
          {rightLabel && (
            <div
              className={clsx('text-[#ccc] text-xs', onRightLabelClick && 'cursor-pointer')}
              onClick={onRightLabelClick}>
              {rightLabel}
            </div>
          )}
        </div>
      )}
      <div className="flex flex-col">
        {children}
        {helper && <div className="text-xs text-error font-bold mt-2">{helper}</div>}
      </div>
    </div>
  );
};

export default InputGroup;

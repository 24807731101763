import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import BaseHeaderNavigation from './BaseHeaderNavigation';

interface BaseNavigationItem {
  name: string | React.ReactNode;
}
interface NavigationItemWithLink extends BaseNavigationItem {
  path: string;
}
interface NavigationItemWithPopup extends BaseNavigationItem {
  popup: { path: string; id: string; options?: string };
}
interface HeaderNavigationProps {
  navigation: {
    name: string | null;
    items: (NavigationItemWithLink | NavigationItemWithPopup)[];
  }[];
  lowercaseItems?: boolean;
}
const HeaderNavigation: React.FC<HeaderNavigationProps> = ({ navigation, lowercaseItems }) => {
  const pathMatches = useMatches();
  const currentPath = useMemo(() => {
    const paths = [...pathMatches].reverse().map((match) => match.pathname);
    return paths.find((path) =>
      navigation.some((category) =>
        category.items.some(
          (item) => Object.prototype.hasOwnProperty.call(item, 'path') && (item as NavigationItemWithLink).path === path
        )
      )
    );
  }, [navigation, pathMatches]);

  return (
    <BaseHeaderNavigation
      childrenDesktop={navigation.map((category) => (
        <div key={category.name?.toString()} className="flex flex-row">
          {category.name && <div className="font-bold w-32 flex items-center">{category.name}</div>}
          <div className="flex flex-row gap-8">
            {category.items.map((item) =>
              Object.prototype.hasOwnProperty.call(item, 'popup') ? (
                <Disclosure.Button
                  key={(item as NavigationItemWithPopup).popup.path}
                  as="span"
                  className={clsx(
                    'block px-2 py-1 hover:bg-light rounded-md cursor-pointer',
                    lowercaseItems && 'lowercase'
                  )}
                  onClick={() => {
                    const popup = window.open(
                      (item as NavigationItemWithPopup).popup.path,
                      (item as NavigationItemWithPopup).popup.id,
                      (item as NavigationItemWithPopup).popup.options
                    );
                    if (popup) popup.focus();
                  }}>
                  {item.name}
                </Disclosure.Button>
              ) : (
                <Disclosure.Button
                  key={(item as NavigationItemWithLink).path}
                  as="a"
                  href={(item as NavigationItemWithLink).path}
                  className={clsx(
                    'block px-2 py-1 hover:bg-light rounded-md',
                    lowercaseItems && 'lowercase',
                    currentPath === (item as NavigationItemWithLink).path && 'bg-light'
                  )}>
                  {item.name}
                </Disclosure.Button>
              )
            )}
          </div>
        </div>
      ))}
      childrenMobile={navigation.map((category) => (
        <div key={category.name?.toString()}>
          {!!category.name && <div className="bg-dark text-white px-3 py-1 font-bold">{category.name}</div>}
          <div className="mt-1 space-y-1">
            {category.items.map((item) =>
              Object.prototype.hasOwnProperty.call(item, 'popup') ? (
                <Disclosure.Button
                  key={(item as NavigationItemWithPopup).popup.path}
                  as="span"
                  className={clsx('block px-3 py-2 font-medium cursor-pointer', lowercaseItems && 'lowercase')}
                  onClick={() => {
                    const popup = window.open(
                      (item as NavigationItemWithPopup).popup.path,
                      (item as NavigationItemWithPopup).popup.id,
                      (item as NavigationItemWithPopup).popup.options
                    );
                    if (popup) popup.focus();
                  }}>
                  {item.name}
                </Disclosure.Button>
              ) : (
                <Disclosure.Button
                  key={(item as NavigationItemWithLink).path}
                  as="a"
                  href={(item as NavigationItemWithLink).path}
                  className={clsx(
                    'block px-3 py-2 font-medium rounded-md',
                    lowercaseItems && 'lowercase',
                    currentPath === (item as NavigationItemWithLink).path && 'bg-light'
                  )}>
                  {item.name}
                </Disclosure.Button>
              )
            )}
          </div>
        </div>
      ))}
    />
  );
};

export default HeaderNavigation;

import { AccountState, AdvertiserType } from '@prisma/client';
import useDashboardAdvertisersController, {
  ADVERTISER_COLUMNS,
  AdvertiserFilter,
} from '../../../controllers/Dashboard/Advertisers';
import dayjs from 'dayjs';
import { Constants } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import Button from '../../../components/Button';
import { useState } from 'react';
import Input from '../../../components/Input';
import { Helmet } from 'react-helmet';

interface DashboardAdvertisersMainViewProps {
  hideColumns?: (keyof typeof ADVERTISER_COLUMNS)[];
  defaultOrderColumn?: keyof typeof ADVERTISER_COLUMNS;
  filter?: AdvertiserFilter | 'search';
  filterOperator?: 'AND' | 'OR';
  lastLoginAtContext?: 'today' | 'yesterday';
  popupSubPage?: string;
  disableRowFormatting?: boolean;
}

const DashboardAdvertisersMainView: React.FC<DashboardAdvertisersMainViewProps> = ({
  defaultOrderColumn,
  hideColumns,
  filter,
  filterOperator,
  lastLoginAtContext,
  popupSubPage,
  disableRowFormatting,
}) => {
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState<AdvertiserFilter | undefined>(undefined);
  const controller = useDashboardAdvertisersController(
    { column: defaultOrderColumn ?? 'id', direction: 'desc' },
    filter !== 'search' ? filter : searchFilter,
    filter !== 'search' ? filterOperator : 'OR'
  );

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchFields: NonNullable<AdvertiserFilter>[number]['field'][] = [
      'baseBusinessName',
      'baseBusinessAppendix',
      'baseCity',
      'baseCountry',
      'baseFirstName',
      'baseLastName',
      'baseStreet',
      'baseZip',
      'invoiceBusinessName',
      'invoiceBusinessAppendix',
      'invoiceCity',
      'invoiceCountry',
      'invoiceStreet',
      'invoiceZip',
      'email',
      'invoiceEmail',
    ];
    if (search) {
      setSearchFilter(searchFields.map((field) => ({ field, operator: 'containsInsensitive', value: search.trim() })));
    }
  };

  return (
    <>
      <Helmet>
        <title>[JM] Ins Übersicht</title>
      </Helmet>
      {filter === 'search' && (
        <div className="flex items-center justify-center py-2 bg-light">
          <form className="flex items-center" onSubmit={handleSearchSubmit}>
            <Input onChange={(v) => setSearch(v)} value={search} className="w-96" />
            <Button
              title="Suche"
              className={clsx('ml-4', controller.isLoading && '!cursor-wait')}
              type="submit"
              disabled={controller.isLoading}
            />
          </form>
        </div>
      )}
      <table className="min-w-full text-left [&>*>tr>*]:px-1 [&>*>tr>*]:py-2 overflow-x-scroll">
        <thead className="lowercase bg-light text-2xs h-12">
          <tr>
            {Object.entries(ADVERTISER_COLUMNS).map(([key, value]) => {
              if (hideColumns && hideColumns.includes(key as keyof typeof ADVERTISER_COLUMNS)) {
                return null;
              }
              return (
                <th
                  key={key}
                  className={clsx(
                    'border-x border-x-border border-dotted',
                    controller.currentSort === key ? 'font-bold' : 'font-normal',
                    value.sortable && '!cursor-pointer hover:opacity-50'
                  )}
                  onClick={
                    value.sortable ? () => controller.handleSort(key as keyof typeof ADVERTISER_COLUMNS) : undefined
                  }>
                  {value.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-xs">
          {controller.advertisers.map((advertiser, idx) => (
            <tr
              key={advertiser.id}
              className={clsx(
                'hover:bg-gray cursor-pointer',
                !disableRowFormatting &&
                  (() => {
                    if (advertiser.state === AccountState.NEW) return 'text-[#999]';
                    else if (advertiser.state === AccountState.DELETED_BY_USER) return 'text-[#999] italic';
                    else if (advertiser.state === AccountState.DELETED_BY_ADMIN)
                      return 'text-[#999] italic line-through';
                    else if (advertiser.state === AccountState.LOCKED) return 'text-error line-through';
                  })()
              )}
              onClick={() => controller.handleAdvertiserClick(advertiser.id, popupSubPage)}>
              {Object.entries(ADVERTISER_COLUMNS).map(([key]) => {
                if (hideColumns && hideColumns.includes(key as keyof typeof ADVERTISER_COLUMNS)) {
                  return null;
                }

                let data = '';
                let extraClassNames = '';
                switch (key) {
                  case 'count':
                    data = ((controller.page - 1) * Constants.ADMIN_PAGE_ENTRY_LIMIT + idx + 1).toString();
                    break;
                  case 'id':
                    data = advertiser.id.toString();
                    break;
                  case 'baseService':
                    data = advertiser.baseServiceId.toString();
                    break;
                  case 'lastLoginService':
                    if (advertiser.lastLoginServiceIds.length === 0) data = '';
                    else data = [...advertiser.lastLoginServiceIds].reverse()[0].toString();
                    break;
                  case 'kind':
                    data = advertiser.type === AdvertiserType.COMPANY ? 'g' : 'p';
                    break;
                  case 'businessName':
                    data =
                      advertiser.type === AdvertiserType.COMPANY ? advertiser.baseBusinessName ?? '' : '- privat -';
                    break;
                  case 'salutation':
                    data = Constants.SALUTATION_OPTIONS[advertiser.baseSalutation];
                    break;
                  case 'firstName':
                    data = advertiser.baseFirstName;
                    break;
                  case 'lastName':
                    data = advertiser.baseLastName;
                    break;
                  case 'country':
                    data = advertiser.baseCountry;
                    break;
                  case 'zipCode':
                    data = advertiser.baseZip;
                    break;
                  case 'city':
                    data = advertiser.baseCity;
                    break;
                  case 'lastLoginAtDate':
                    if (advertiser.lastLogins.length === 0) data = '';
                    else
                      data = dayjs(advertiser.lastLogins.sort((a, b) => b.getTime() - a.getTime())[0]).format(
                        'DD.MM.YY'
                      );
                    break;
                  case 'createdAt':
                    data = dayjs(advertiser.createdAt).format('DD.MM.YY');
                    break;
                  case 'deletedAt':
                    data = advertiser.deletedAt ? dayjs(advertiser.deletedAt).format('DD.MM.YY') : '';
                    break;
                  case 'lastLoginAtTime':
                    data = dayjs(
                      (advertiser.lastLogins || [])
                        .filter((d) =>
                          lastLoginAtContext === 'yesterday' ? dayjs(d).isBefore(dayjs().startOf('day')) : true
                        )
                        .sort((a, b) => b.getTime() - a.getTime())[0]
                    ).format('HH:mm');
                    break;
                  case 'loginCount':
                    data = advertiser.loginCount.toString();
                    break;
                  case 'jobsOnline':
                    data = advertiser.jobsActive.toString();
                    break;
                  case 'jobsTotal':
                    data = advertiser.jobsTotal.toString();
                    break;
                  case 'discountPercentage':
                    data = advertiser.discountPercentage.toString();
                    extraClassNames = 'text-right';
                    break;
                  case 'email':
                    data = advertiser.email;
                    break;
                  default:
                    data = key;
                }
                return (
                  <td key={key} className={clsx('border border-border', extraClassNames)}>
                    {data}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row gap-2 w-full justify-center my-4">
        <Button onClick={() => controller.handlePageChange(1)} secondary className="!font-normal" title="|<" />
        <Button
          onClick={() => controller.handlePageChange(controller.page - 1)}
          secondary
          className="!font-normal"
          title="<"
        />
        {/* Show buttons for the current page (controller.page) in the center as well as the 3 pages before and after */}
        {Array.from({ length: 7 })
          .map((_, idx) => controller.page - 3 + idx)
          .filter((page) => page > 0 && page <= controller.pages)
          .map((page) => (
            <Button
              key={page}
              onClick={() => controller.handlePageChange(page)}
              secondary={page !== controller.page}
              className="!font-normal"
              title={page.toString()}
            />
          ))}
        <Button
          onClick={() => controller.handlePageChange(controller.page + 1)}
          secondary
          className="!font-normal"
          title=">"
        />
        <Button
          onClick={() => controller.handlePageChange(controller.pages)}
          secondary
          className="!font-normal"
          title=">|"
        />
      </div>
    </>
  );
};

export default DashboardAdvertisersMainView;

import { useQuery, useMutation } from '@tanstack/react-query';
import { UseQueryTypeHelper, UseMutationTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';

export enum FeedQueryKeys {
  GET_BY_ADVERTISER_ID = 'FEED_GET_BY_ADVERTISER_ID',
}

export const useGetFeedsByAdvertiserId = (
  advertiserId: number,
  options?: UseQueryTypeHelper<typeof trpc.feed.getByAdvertiserId.query | (() => null)>
) =>
  useQuery(
    [FeedQueryKeys.GET_BY_ADVERTISER_ID, advertiserId],
    () => trpc.feed.getByAdvertiserId.query({ advertiserId }),
    options
  );

export const useCreateFeed = (
  options?: UseMutationTypeHelper<typeof trpc.feed.create.mutate, Parameters<typeof trpc.feed.create.mutate>[0]>
) => useMutation((data) => trpc.feed.create.mutate(data), options);

export const useUpdateFeed = (
  options?: UseMutationTypeHelper<typeof trpc.feed.update.mutate, Parameters<typeof trpc.feed.update.mutate>[0]>
) => useMutation((data) => trpc.feed.update.mutate(data), options);

export const useDeleteFeed = (
  options?: UseMutationTypeHelper<typeof trpc.feed.delete.mutate, Parameters<typeof trpc.feed.delete.mutate>[0]>
) => useMutation((data) => trpc.feed.delete.mutate(data), options);

import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
// import DashboardOutlet from './app/Dashboard/Outlet';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LoginView from './app/views/Login';
import DashboardTableOutlet from './app/views/Dashboard/TableOutlet';
import DashboardAdvertisersMainView from './app/views/Dashboard/Advertisers/Main';
import dayjs from 'dayjs';
import { AccountState, AdvertiserType, JobState } from '@prisma/client';
import DashboardAdvertiserDetailsSettingsView from './app/views/Dashboard/AdvertiserDetails/Settings';
import DashboardAdvertiserDetailsOutlet from './app/views/Dashboard/AdvertiserDetails/_outlet';
import DashboardAdvertiserDetailsLogoView from './app/views/Dashboard/AdvertiserDetails/Logo';
import DashboardAdvertiserDetailsUpdateDataView from './app/views/Dashboard/AdvertiserDetails/UpdateData';
import DashboardJobsMainView from './app/views/Dashboard/Jobs/Main';
import DashboardJobDetailsMainView from './app/views/Dashboard/JobDetails/Main';
import DashboardJobDetailsOutlet from './app/views/Dashboard/JobDetails/_outlet';
import MainOutlet from './app/views/MainOutlet';
import DashboardCouponsMainView from './app/views/Dashboard/Coupons/Main';
import DashboardAdvertiserDetailsJobsView from './app/views/Dashboard/AdvertiserDetails/Jobs';
import DashboardAdvertiserDetailsInvoicesView from './app/views/Dashboard/AdvertiserDetails/Invoices';
import DashboardAdminMainView from './app/views/Dashboard/Admin/Main';
import DashboardVisitorTrackerView from './app/views/Dashboard/VisitorTracker';
import DashboardAdvertiserDetailsFeedsView from './app/views/Dashboard/AdvertiserDetails/Feeds';

Sentry.init({
  dsn:
    process.env.NODE_ENV === 'development'
      ? undefined
      : 'https://5c39ebc29a4446fdaceebd23a3dcbfd1@sentry.kuatsu.de/4505358463664128',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', new RegExp(`^${process.env.NX_API_BASE_URL}`)],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.2 : 1.0, // 20% of samples in production, 100% in development
  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // 10% of sessions in production, 100% in development
  replaysOnErrorSampleRate: 1.0, // Sample everything when an error happens
  environment: process.env.NODE_ENV,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: process.env['NODE_ENV'] === 'development' ? 1000 * 5 : 1000 * 60,
      refetchIntervalInBackground: true,
    },
  },
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <MainOutlet />,
    children: [
      {
        path: '/',
        element: <LoginView />,
      },
      {
        path: '/dashboard/inserenten',
        element: <DashboardTableOutlet />,
        children: [
          {
            path: '/dashboard/inserenten/heute-neu',
            element: (
              <DashboardAdvertisersMainView
                hideColumns={['createdAt', 'deletedAt', 'lastLoginAtTime']}
                defaultOrderColumn="id"
                filter={[{ field: 'createdAt', operator: 'gte', value: dayjs().startOf('day').toDate() }]}
              />
            ),
          },
          {
            path: '/dashboard/inserenten/neue-daten',
            element: (
              <DashboardAdvertisersMainView
                hideColumns={['createdAt', 'deletedAt', 'lastLoginAtTime']}
                defaultOrderColumn="id"
                filter={[
                  { field: 'needsBaseCheck', operator: 'equals', value: true },
                  { field: 'needsInvoiceCheck', operator: 'equals', value: true },
                ]}
                filterOperator="OR"
                popupSubPage="daten"
              />
            ),
          },
          {
            path: '/dashboard/inserenten/heute-aktiv',
            element: (
              <DashboardAdvertisersMainView
                hideColumns={['createdAt', 'deletedAt', 'lastLoginAtDate']}
                defaultOrderColumn="lastLoginAtTime"
                filter={[{ field: 'lastLogin', operator: 'gte', value: dayjs().startOf('day').toDate() }]}
              />
            ),
          },
          {
            path: '/dashboard/inserenten/gestern-aktiv',
            element: (
              <DashboardAdvertisersMainView
                hideColumns={['createdAt', 'deletedAt', 'lastLoginAtDate']}
                defaultOrderColumn="lastLoginAtTime"
                filter={[
                  { field: 'lastLogin', operator: 'gte', value: dayjs().subtract(1, 'day').startOf('day').toDate() },
                  { field: 'lastLogin', operator: 'lte', value: dayjs().subtract(1, 'day').endOf('day').toDate() },
                ]}
                lastLoginAtContext="yesterday"
              />
            ),
          },
          {
            path: '/dashboard/inserenten/alle',
            element: (
              <DashboardAdvertisersMainView
                hideColumns={['createdAt', 'deletedAt', 'lastLoginAtTime']}
                defaultOrderColumn="id"
              />
            ),
          },
          {
            path: '/dashboard/inserenten/alle-privat',
            element: (
              <DashboardAdvertisersMainView
                hideColumns={['createdAt', 'deletedAt', 'lastLoginAtTime', 'kind']}
                defaultOrderColumn="id"
                filter={[{ field: 'type', operator: 'equals', value: AdvertiserType.PRIVATE }]}
              />
            ),
          },
          {
            path: '/dashboard/inserenten/alle-gewerblich',
            element: (
              <DashboardAdvertisersMainView
                hideColumns={['createdAt', 'deletedAt', 'lastLoginAtTime', 'kind']}
                defaultOrderColumn="id"
                filter={[{ field: 'type', operator: 'equals', value: AdvertiserType.COMPANY }]}
              />
            ),
          },
          {
            path: '/dashboard/inserenten/geloescht',
            element: (
              <DashboardAdvertisersMainView
                hideColumns={['createdAt', 'lastLoginAtDate', 'lastLoginAtTime']}
                defaultOrderColumn="deletedAt"
                filter={[{ field: 'state', operator: 'equals', value: AccountState.DELETED_BY_USER }]}
                disableRowFormatting
              />
            ),
          },
          {
            path: '/dashboard/inserenten/suche',
            element: (
              <DashboardAdvertisersMainView hideColumns={['lastLoginAtTime']} defaultOrderColumn="id" filter="search" />
            ),
          },
        ],
      },
      {
        path: '/dashboard/inserenten/:id',
        element: <DashboardAdvertiserDetailsOutlet />,
        children: [
          {
            path: '/dashboard/inserenten/:id',
            element: <DashboardAdvertiserDetailsSettingsView />,
          },
          {
            path: '/dashboard/inserenten/:id/daten',
            element: <DashboardAdvertiserDetailsUpdateDataView />,
          },
          {
            path: '/dashboard/inserenten/:id/logo',
            element: <DashboardAdvertiserDetailsLogoView />,
          },
          {
            path: '/dashboard/inserenten/:id/feeds',
            element: <DashboardAdvertiserDetailsFeedsView />,
          },
          {
            path: '/dashboard/inserenten/:id/anzeigen',
            element: <DashboardAdvertiserDetailsJobsView />,
          },
          {
            path: '/dashboard/inserenten/:id/rechnungen',
            element: <DashboardAdvertiserDetailsInvoicesView />,
          },
        ],
      },
      {
        path: '/dashboard/anzeigen',
        element: <DashboardTableOutlet />,
        children: [
          {
            path: '/dashboard/anzeigen/pruefen',
            element: (
              <DashboardJobsMainView
                defaultOrderColumn="id"
                filter={[
                  { field: 'state', operator: 'in', value: [JobState.UNCHECKED, JobState.ACTIVE_UNCHECKED] },
                  { field: 'isEdited', operator: 'equals', value: true },
                ]}
                filterOperator="OR"
                hideColumns={['deletedAt']}
              />
            ),
          },
          {
            path: '/dashboard/anzeigen/online',
            element: (
              <DashboardJobsMainView
                defaultOrderColumn="id"
                filter={[{ field: 'state', operator: 'in', value: [JobState.ACTIVE, JobState.ACTIVE_UNCHECKED] }]}
                hideColumns={['deletedAt']}
              />
            ),
          },
          {
            path: '/dashboard/anzeigen/abgelaufen',
            element: (
              <DashboardJobsMainView
                defaultOrderColumn="activeUntil"
                filter={[{ field: 'state', operator: 'equals', value: JobState.EXPIRED }]}
                hideColumns={['deletedAt']}
                disableRowFormatting
              />
            ),
          },
          {
            path: '/dashboard/anzeigen/alle',
            element: <DashboardJobsMainView defaultOrderColumn="id" hideColumns={['deletedAt']} />,
          },
          {
            path: '/dashboard/anzeigen/geloescht',
            element: (
              <DashboardJobsMainView
                defaultOrderColumn="deletedAt"
                filter={[{ field: 'state', operator: 'equals', value: JobState.DELETED_BY_USER }]}
                hideColumns={['activeUntil']}
                disableRowFormatting
              />
            ),
          },
          {
            path: '/dashboard/anzeigen/suche',
            element: <DashboardJobsMainView defaultOrderColumn="id" filter="search" />,
          },
        ],
      },
      {
        path: '/dashboard/anzeigen/:id',
        element: <DashboardJobDetailsOutlet />,
        children: [
          {
            path: '/dashboard/anzeigen/:id',
            element: <DashboardJobDetailsMainView />,
          },
        ],
      },
      {
        path: '/dashboard/administration/allgemein',
        element: <DashboardTableOutlet />,
        children: [
          {
            path: '/dashboard/administration/allgemein',
            element: <DashboardAdminMainView />,
          },
        ],
      },
      {
        path: '/dashboard/administration/gutschein-codes',
        element: <DashboardTableOutlet />,
        children: [
          {
            path: '/dashboard/administration/gutschein-codes',
            element: <DashboardCouponsMainView defaultOrderColumn="createdAt" />,
          },
        ],
      },
      {
        path: '/dashboard/administration/user-online',
        element: <DashboardVisitorTrackerView />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary
      fallback={(e) => (
        <>
          <p>Fehler</p>
          <p>{JSON.stringify(e)}</p>
        </>
      )}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
);

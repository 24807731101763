import { useEffect, useState } from 'react';
import { useLogin, useVerifySession } from '../hooks/query/Auth';
import { useNavigate } from 'react-router-dom';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { trpc } from '../utils/trpc';

const useLoginController = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string[] | null>(null);

  const { mutate: login, isLoading: isLoggingIn } = useLogin({
    onError: (e) => setError([transformTRPCErrorToMessage(e)]),
    onSuccess: () => {
      navigate('/dashboard/inserenten/heute-aktiv');
    },
  });
  const { mutate: verifySession } = useVerifySession({
    onSuccess: async (data) => {
      if (data.userType !== 'admin') {
        await trpc.auth.logout.mutate();
      } else {
        navigate('/dashboard/inserenten/heute-aktiv');
      }
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ username, password });
  };

  useEffect(() => {
    verifySession();
  }, [verifySession]);

  return {
    username,
    setUsername,
    password,
    setPassword,
    handleSubmit,
    isLoggingIn,
    error,
  };
};

export default useLoginController;

import clsx from 'clsx';

interface SelectBoxProps<T extends string | number> extends React.SelectHTMLAttributes<HTMLSelectElement> {
  value: T | '';
  options: {
    value: T;
    label: string;
  }[];
}

const SelectBox = <T extends string | number>({
  options,
  className,
  placeholder,
  disabled,
  ...rest
}: SelectBoxProps<T>): JSX.Element => {
  return (
    <select
      {...rest}
      className={clsx(
        'w-full border border-border rounded-md overflow-hidden px-3 py-2 bg-white h-10 focus:outline-none focus:shadow-md',
        disabled && 'opacity-50',
        className
      )}
      disabled={disabled}>
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default SelectBox;

import { useMutation, useQuery } from '@tanstack/react-query';
import { UseMutationTypeHelper, UseQueryTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';
import { IAppConfig } from '@jobmatic/backend/config';

export enum AdminQueryKeys {
  GET_CONFIG = 'ADMIN_GET_CONFIG',
  GET_WEBFLOW_STATUS = 'ADMIN_GET_WEBFLOW_STATUS',
  GET_GLOBAL_NAVIGATION_COUNTS = 'ADMIN_GET_GLOBAL_NAVIGATION_COUNTS',
  GET_ADVERTISER_NAVIGATION_COUNTS = 'ADMIN_GET_ADVERTISER_NAVIGATION_COUNTS',
}

export const useGetCurrentConfig = (options?: UseQueryTypeHelper<() => Promise<IAppConfig>>) =>
  useQuery([AdminQueryKeys.GET_CONFIG], () => trpc.system.getConfig.query() as Promise<IAppConfig>, options);

export const useGetWebflowStatus = (options?: UseQueryTypeHelper<typeof trpc.admin.getWebflowStatus.query>) =>
  useQuery([AdminQueryKeys.GET_WEBFLOW_STATUS], () => trpc.admin.getWebflowStatus.query(), options);

export const useUpdateConfig = (
  options?: UseMutationTypeHelper<
    typeof trpc.admin.updateConfig.mutate,
    Parameters<typeof trpc.admin.updateConfig.mutate>[0]
  >
) => useMutation((data) => trpc.admin.updateConfig.mutate(data), options);

export const useUpdateViewsPerDay = (
  options?: UseMutationTypeHelper<
    typeof trpc.admin.updateViewsPerDay.mutate,
    Parameters<typeof trpc.admin.updateViewsPerDay.mutate>[0]
  >
) => useMutation((data) => trpc.admin.updateViewsPerDay.mutate(data), options);

export const usePanicWebflow = (options?: UseMutationTypeHelper<typeof trpc.admin.panicWebflow.mutate, void>) =>
  useMutation(() => trpc.admin.panicWebflow.mutate(), options);

export const useResyncWebflow = (options?: UseMutationTypeHelper<typeof trpc.admin.resyncWebflow.mutate, void>) =>
  useMutation(() => trpc.admin.resyncWebflow.mutate(), options);

export const useTriggerJobletter = (options?: UseMutationTypeHelper<typeof trpc.admin.triggerJobletter.mutate, void>) =>
  useMutation(() => trpc.admin.triggerJobletter.mutate(), options);

export const useProfileApp = (
  options?: UseMutationTypeHelper<typeof trpc.admin.profileApp.mutate, { profileDuration?: number }>
) => useMutation((data) => trpc.admin.profileApp.mutate(data), options);

export const useGetGlobalNavigationCounts = (
  options?: UseQueryTypeHelper<typeof trpc.admin.getGlobalNavigationCounts.query>
) =>
  useQuery([AdminQueryKeys.GET_GLOBAL_NAVIGATION_COUNTS], () => trpc.admin.getGlobalNavigationCounts.query(), options);

export const useGetAdvertiserNavigationCounts = (
  advertiserId: number,
  options?: UseQueryTypeHelper<typeof trpc.admin.getAdvertiserNavigationCounts.query>
) =>
  useQuery(
    [AdminQueryKeys.GET_ADVERTISER_NAVIGATION_COUNTS, advertiserId],
    () => trpc.admin.getAdvertiserNavigationCounts.query({ advertiserId }),
    options
  );

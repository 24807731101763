import { useQuery, useMutation } from '@tanstack/react-query';
import { UseQueryTypeHelper, UseMutationTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';

export enum JobQueryKeys {
  GET_ALL = 'JOB_GET_ALL',
  GET_SINGLE = 'JOB_GET_SINGLE',
  GET_ALL_TAGS = 'JOB_GET_ALL_TAGS',
  GET_BY_ADVERTISER_ID = 'JOB_GET_BY_ADVERTISER_ID',
}

export const useGetAllJobs = (
  data: Parameters<typeof trpc.job.getAll.query>[0],
  options?: UseQueryTypeHelper<typeof trpc.job.getAll.query>
) => useQuery([JobQueryKeys.GET_ALL, data], () => trpc.job.getAll.query(data), options);

export const useGetJobById = (
  jobId: number,
  options?: UseQueryTypeHelper<typeof trpc.job.getById.query | (() => null)>
) => useQuery([JobQueryKeys.GET_SINGLE, jobId], () => trpc.job.getById.query({ id: jobId }), options);

export const useGetJobsByAdvertiserId = (
  advertiserId: number,
  options?: UseQueryTypeHelper<typeof trpc.job.getByAdvertiserId.query | (() => null)>
) =>
  useQuery(
    [JobQueryKeys.GET_BY_ADVERTISER_ID, advertiserId],
    () => trpc.job.getByAdvertiserId.query({ advertiserId }),
    options
  );

export const useGetAllJobTags = (options?: UseQueryTypeHelper<typeof trpc.job.getAllTags.query | (() => null)>) =>
  useQuery([JobQueryKeys.GET_ALL_TAGS], () => trpc.job.getAllTags.query(), options);

export const useUpdateJob = (
  options?: UseMutationTypeHelper<typeof trpc.job.update.mutate, Parameters<typeof trpc.job.update.mutate>[0]>
) => useMutation((data) => trpc.job.update.mutate(data), options);

export const useDeleteJob = (
  options?: UseMutationTypeHelper<typeof trpc.job.delete.mutate, Parameters<typeof trpc.job.delete.mutate>[0]>
) => useMutation((data) => trpc.job.delete.mutate(data), options);

export const useActivateJob = (
  options?: UseMutationTypeHelper<typeof trpc.job.activate.mutate, Parameters<typeof trpc.job.activate.mutate>[0]>
) => useMutation((data) => trpc.job.activate.mutate(data), options);

export const useFlagJobAsChecked = (
  options?: UseMutationTypeHelper<typeof trpc.job.markChecked.mutate, Parameters<typeof trpc.job.markChecked.mutate>[0]>
) => useMutation((data) => trpc.job.markChecked.mutate(data), options);

import useDashboardAdvertiserDetailsInvoicesController, {
  ADVERTISER_INVOICES_COLUMNS,
  AdvertiserInvoicesFilter,
} from '../../../controllers/Dashboard/AdvertiserDetails/Invoices';
import dayjs from 'dayjs';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import Button from '../../../components/Button';
import { InvoiceState, PaymentMethod } from '@prisma/client';
import { useParams } from 'react-router-dom';

interface DashboardAdvertiserDetailsInvoicesViewProps {
  hideColumns?: (keyof typeof ADVERTISER_INVOICES_COLUMNS)[];
  defaultOrderColumn?: keyof typeof ADVERTISER_INVOICES_COLUMNS;
  filter?: AdvertiserInvoicesFilter;
  filterOperator?: 'AND' | 'OR';
}

const DashboardAdvertiserDetailsInvoicesView: React.FC<DashboardAdvertiserDetailsInvoicesViewProps> = ({
  defaultOrderColumn,
  hideColumns,
  filter,
  filterOperator,
}) => {
  const params = useParams();
  const controller = useDashboardAdvertiserDetailsInvoicesController(
    parseInt(params.id ?? '-1'),
    { column: defaultOrderColumn ?? 'invoiceNumber', direction: 'desc' },
    filter,
    filterOperator
  );

  return (
    <>
      <table className="min-w-full text-left [&>*>tr>*]:px-1 [&>*>tr>*]:py-2 overflow-x-scroll">
        <thead className="lowercase bg-light text-2xs h-12">
          <tr>
            {Object.entries(ADVERTISER_INVOICES_COLUMNS).map(([key, value]) => {
              if (hideColumns && hideColumns.includes(key as keyof typeof ADVERTISER_INVOICES_COLUMNS)) {
                return null;
              }
              return (
                <th
                  key={key}
                  className={clsx(
                    'border-x border-x-border border-dotted',
                    controller.currentSort === key ? 'font-bold' : 'font-normal',
                    value.sortable && '!cursor-pointer hover:opacity-50'
                  )}
                  onClick={
                    value.sortable
                      ? () => controller.handleSort(key as keyof typeof ADVERTISER_INVOICES_COLUMNS)
                      : undefined
                  }>
                  {value.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-xs">
          {controller.invoices.map((invoice, idx) => (
            <tr
              key={invoice.id}
              className={clsx(
                'hover:bg-gray cursor-pointer',
                (() => {
                  if (invoice.state === InvoiceState.PAID) return 'text-[#999]';
                  else if (invoice.state === InvoiceState.CANCELED) return 'text-[#999] line-through';
                  else if (dayjs(invoice.dueDate).isBefore(dayjs()) && invoice.state === InvoiceState.OPEN)
                    return 'text-error';
                })()
              )}
              onClick={() => controller.handleInvoiceClick(invoice.id)}>
              {Object.entries(ADVERTISER_INVOICES_COLUMNS).map(([key]) => {
                if (hideColumns && hideColumns.includes(key as keyof typeof ADVERTISER_INVOICES_COLUMNS)) {
                  return null;
                }

                let data = '';
                let extraClassNames = '';
                switch (key) {
                  case 'count':
                    data = ((controller.page - 1) * Constants.ADMIN_PAGE_ENTRY_LIMIT + idx + 1).toString();
                    break;
                  case 'invoiceDate':
                    data = dayjs(invoice.invoiceDate).format('DD.MM.YYYY');
                    break;
                  case 'dueDate':
                    data = dayjs(invoice.dueDate).format('DD.MM.YYYY');
                    break;
                  case 'jobBaseServiceId':
                    data = invoice.jobBaseServiceId.toString();
                    break;
                  case 'invoiceNumber':
                    data = invoice.invoiceNumber;
                    break;
                  case 'state':
                    data = Helpers.parseInvoiceState(invoice.state);
                    break;
                  case 'netPrice':
                    data = Helpers.parsePrice(invoice.invoiceAmountNet);
                    extraClassNames = 'text-right';
                    break;
                  case 'grossPrice':
                    data = Helpers.parsePrice(invoice.invoiceAmountGross);
                    extraClassNames = 'text-right';
                    break;
                  case 'paymentMethod':
                    data = invoice.paymentMethod === PaymentMethod.PAYPAL ? 'pp' : '';
                    break;
                  default:
                    data = key;
                }
                return (
                  <td key={key} className={clsx('border border-border', extraClassNames)}>
                    {data}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row gap-2 w-full justify-center my-4">
        <Button onClick={() => controller.handlePageChange(1)} secondary className="!font-normal" title="|<" />
        <Button
          onClick={() => controller.handlePageChange(controller.page - 1)}
          secondary
          className="!font-normal"
          title="<"
        />
        {/* Show buttons for the current page (controller.page) in the center as well as the 3 pages before and after */}
        {Array.from({ length: 7 })
          .map((_, idx) => controller.page - 3 + idx)
          .filter((page) => page > 0 && page <= controller.pages)
          .map((page) => (
            <Button
              key={page}
              onClick={() => controller.handlePageChange(page)}
              secondary={page !== controller.page}
              className="!font-normal"
              title={page.toString()}
            />
          ))}
        <Button
          onClick={() => controller.handlePageChange(controller.page + 1)}
          secondary
          className="!font-normal"
          title=">"
        />
        <Button
          onClick={() => controller.handlePageChange(controller.pages)}
          secondary
          className="!font-normal"
          title=">|"
        />
      </div>
    </>
  );
};

export default DashboardAdvertiserDetailsInvoicesView;

import { useParams } from 'react-router-dom';
import useDashboardAdvertiserDetailsFeedsController from '../../../controllers/Dashboard/AdvertiserDetails/Feeds';
import { FeedParser } from '@prisma/client';
import clsx from 'clsx';
import SelectBox from '../../../components/SelectBox';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import InputGroup from '../../../components/InputGroup';
import Input from '../../../components/Input';
import dayjs from 'dayjs';
import { Helpers } from '@jobmatic/shared/utils';

const DashboardAdvertiserDetailsFeedsView: React.FC = () => {
  const params = useParams();
  const controller = useDashboardAdvertiserDetailsFeedsController(parseInt(params.id || '-1'));

  return (
    <div className="lg:grid grid-cols-2 text-sm">
      <div className="flex flex-col gap-4 lg:pr-8 lg:border-r border-r-border">
        <div>
          <h2 className="text-xl font-semibold mb-4">Bestehende Feeds</h2>
          <div className="flex flex-col divide-y divide-border [&>*]:py-4">
            {controller.existingFeeds.map((feed) => (
              <div className="pt-0 flex flex-col gap-4">
                <h2 className="text-lg font-semibold">Feed {feed.id}</h2>
                <span className="text-sm text-text-muted">
                  Zuletzt abgerufen:{' '}
                  {feed.lastFetchedAt ? dayjs(feed.lastFetchedAt).format('DD.MM.YYYY HH:mm:ss') : 'nie'}
                </span>
                <InputGroup label="Feed-URL">
                  <Input
                    value={feed.url}
                    onChange={(e) => controller.updateExistingFeed(feed.id, (prev) => ({ ...prev, url: e }))}
                  />
                </InputGroup>
                <InputGroup label="Parser">
                  <SelectBox
                    value={feed.parser}
                    onChange={(e) =>
                      controller.updateExistingFeed(feed.id, (prev) => ({
                        ...prev,
                        parser: e.target.value as FeedParser,
                      }))
                    }
                    options={Object.values(FeedParser).map((parser) => ({
                      value: parser,
                      label: Helpers.parseFeedParserName(parser),
                    }))}
                  />
                </InputGroup>
                <InputGroup label="Schaltungs-Service">
                  <SelectBox
                    value={feed.serviceId}
                    onChange={(e) =>
                      controller.updateExistingFeed(feed.id, (prev) => ({
                        ...prev,
                        serviceId: parseInt(e.target.value),
                      }))
                    }
                    options={controller.services.map((service) => ({
                      value: service.id,
                      label: service.name,
                    }))}
                  />
                </InputGroup>
                <InputGroup label="Beschreibung">
                  <textarea
                    className="mt-1 w-full px-3 py-2 border border-border rounded h-24"
                    value={feed.description}
                    onChange={(e) =>
                      controller.updateExistingFeed(feed.id, (prev) => ({ ...prev, description: e.target.value }))
                    }
                  />
                </InputGroup>
                <Checkbox
                  label="Schaltung als Hotjob"
                  checked={feed.isHotjob}
                  onChange={() =>
                    controller.updateExistingFeed(feed.id, (prev) => ({ ...prev, isHotjob: !prev.isHotjob }))
                  }
                />
                <div className="flex flex-row gap-4 w-full justify-end">
                  <Button
                    className={clsx('mt-1 self-end', controller.isDeletingFeed && '!cursor-wait')}
                    disabled={controller.isDeletingFeed}
                    title="löschen"
                    onClick={() => controller.handleSubmitDeleteFeed(feed.id)}
                  />
                  <Button
                    className={clsx('mt-1 self-end', controller.isUpdatingFeed && '!cursor-wait')}
                    disabled={controller.isUpdatingFeed}
                    title="speichern"
                    onClick={() => controller.handleSubmitFeedUpdate(feed.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 lg:pl-8 mt-8 lg:mt-0">
        <h2 className="text-xl font-semibold mb-4">Neuen Feed anlegen</h2>
        <InputGroup label="Feed-URL">
          <Input value={controller.url} onChange={(e) => controller.setUrl(e)} />
        </InputGroup>
        <InputGroup label="Parser">
          <SelectBox
            value={controller.parser}
            onChange={(e) => controller.setParser(e.target.value as FeedParser)}
            options={Object.values(FeedParser).map((parser) => ({
              value: parser,
              label: Helpers.parseFeedParserName(parser),
            }))}
          />
        </InputGroup>
        <InputGroup label="Schaltungs-Service">
          <SelectBox
            value={controller.serviceId}
            onChange={(e) => controller.setServiceId(parseInt(e.target.value))}
            options={controller.services.map((service) => ({
              value: service.id,
              label: service.name,
            }))}
          />
        </InputGroup>
        <InputGroup label="Beschreibung">
          <textarea
            className="mt-1 w-full px-3 py-2 border border-border rounded h-24"
            value={controller.description}
            onChange={(e) => controller.setDescription(e.target.value)}
          />
        </InputGroup>
        <Checkbox
          label="Schaltung als Hotjob"
          checked={controller.isHotjob}
          onChange={() => controller.setIsHotjob(!controller.isHotjob)}
        />
        <Button
          className={clsx('mt-1 self-end', controller.isCreatingFeed && '!cursor-wait')}
          disabled={controller.isCreatingFeed}
          title="speichern"
          onClick={() => controller.handleSubmitNewFeed()}
        />
      </div>
    </div>
  );
};

export default DashboardAdvertiserDetailsFeedsView;

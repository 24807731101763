import { useMutation } from '@tanstack/react-query';
import { UseMutationTypeHelper } from '@jobmatic/shared/types';
import { TRPCClientError } from '@trpc/client';
import { trpc } from '../../utils/trpc';

export const useLogin = (
  options?: UseMutationTypeHelper<typeof trpc.auth.loginAdmin.mutate, { username: string; password: string }>
) => useMutation((data) => trpc.auth.loginAdmin.mutate(data), options);

export const useVerifySession = (options?: UseMutationTypeHelper<typeof trpc.auth.check.mutate, void>) =>
  useMutation(() => trpc.auth.check.mutate(), {
    retry: (failureCount, error) => {
      if (error instanceof TRPCClientError && error.data?.code === 'UNAUTHORIZED') {
        return false;
      }
      return failureCount < 3;
    },
    ...options,
  });

import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

interface BaseHeaderNavigationProps {
  childrenDesktop: React.ReactNode;
  childrenMobile?: React.ReactNode;
}
const BaseHeaderNavigation: React.FC<BaseHeaderNavigationProps> = ({ childrenDesktop, childrenMobile }) => {
  return (
    <Disclosure as="nav" className="bg-gray py-4 px-2 text-sm flex flex-col">
      {({ open }) => (
        <>
          <div className="self-start relative flex items-center justify-between">
            {!!childrenMobile && (
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 hover:bg-light focus:outline-none">
                  <span className="sr-only">Hauptmenü öffnen</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            )}
            <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
              <div className={clsx('ml-6', !!childrenMobile && 'hidden lg:block')}>
                <div className="flex flex-col">{childrenDesktop}</div>
              </div>
            </div>
          </div>

          {!!childrenMobile && (
            <Disclosure.Panel className="lg:hidden">
              <div className="px-2 pb-3 pt-2 flex flex-col gap-4">{childrenMobile}</div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  );
};

export default BaseHeaderNavigation;

import { useQuery } from '@tanstack/react-query';
import { UseQueryTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';

export enum SystemQueryKeys {
  GET_VISITOR_TRACKS = 'SYSTEM_GET_VISITOR_TRACKS',
}

export const useGetVisitorTracks = (options?: UseQueryTypeHelper<typeof trpc.system.getUserTracks.query>) =>
  useQuery([SystemQueryKeys.GET_VISITOR_TRACKS], () => trpc.system.getUserTracks.query(), options);

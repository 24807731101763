import { Constants } from '@jobmatic/shared/utils';
import { ErrorMessages, transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  AdvertiserQueryKeys,
  useDeleteLogo,
  useGetAdvertiserById,
  useGetLogo,
  useUploadLogo,
} from '../../../hooks/query/Advertiser';
import { useQueryClient } from '@tanstack/react-query';

const useDashboardAdvertiserDetailsLogoController = (advertiserId: number) => {
  const queryClient = useQueryClient();

  const [logo, setLogo] = useState<{
    base64: string;
    mimeType: (typeof Constants.ALLOWED_LOGO_MIME_TYPES)[number];
  } | null>(null);

  const { data: currentLogo } = useGetLogo(advertiserId);
  const { data: advertiser } = useGetAdvertiserById(advertiserId);
  const { mutate: uploadLogo, isLoading: isUploading } = useUploadLogo({
    onSuccess: () => {
      // TODO: proper success alert
      alert('Das Logo wurde erfolgreich hochgeladen.');
      queryClient.setQueryData([AdvertiserQueryKeys.GET_LOGO, advertiserId], logo);
      setLogo(null);
    },
    onError: (e) => {
      // TODO: proper error alert
      alert(transformTRPCErrorToMessage(e));
    },
  });
  const { mutate: deleteLogo, isLoading: isDeleting } = useDeleteLogo({
    onSuccess: () => {
      // TODO: proper success alert
      alert('Das Logo wurde erfolgreich gelöscht.');
      queryClient.setQueryData([AdvertiserQueryKeys.GET_LOGO, advertiserId], null);
      setLogo(null);
    },
    onError: (e) => {
      // TODO: proper error alert
      alert(transformTRPCErrorToMessage(e));
    },
  });

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();
    const file = acceptedFiles[0];

    reader.onabort = () => console.log('file reading was aborted');
    reader.onerror = () => console.log('file reading has failed');
    reader.onload = () => {
      // convert to base64 for uploading
      const base64Url = reader.result?.toString();
      if (!base64Url) return;

      // get mime type
      const mimeType = base64Url?.toString().split(',')[0].split(':')[1].split(';')[0];
      const base64 = base64Url?.toString().split(',')[1];

      if (!(Constants.ALLOWED_LOGO_MIME_TYPES as readonly string[]).includes(mimeType)) {
        alert(ErrorMessages.logo_invalid_mime_type); // TODO: proper error alert
        return;
      }

      setLogo({ base64, mimeType: mimeType as (typeof Constants.ALLOWED_LOGO_MIME_TYPES)[number] });
    };
    reader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = () => {
    if (!logo) return;

    uploadLogo({
      base64: logo.base64,
      mimeType: logo.mimeType,
      advertiserId,
    });
  };

  const handleDelete = () => {
    deleteLogo({ advertiserId });
  };

  return {
    dropzone: { getRootProps, getInputProps, isDragActive },
    currentLogo,
    logo,
    advertiser,
    handleSubmit,
    handleDelete,
    isUploading,
    isDeleting,
  };
};

export default useDashboardAdvertiserDetailsLogoController;

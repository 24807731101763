import { Constants } from '@jobmatic/shared/utils';
import { useGetAllJobs } from '../../../hooks/query/Job';
import { useMemo, useState } from 'react';
import { useShallowCompareEffect } from 'react-use';

export type AdvertiserJobsFilter = Parameters<typeof useDashboardAdvertiserDetailsJobsController>[2];
export type AdvertiserJobsOrder = { column: keyof typeof ADVERTISER_JOBS_COLUMNS; direction: 'asc' | 'desc' };

export const ADVERTISER_JOBS_COLUMNS = {
  count: { name: '#', sortable: false },
  id: { name: 'ID', sortable: true },
  activatedForServiceId: { name: 'Ins', sortable: true },
  title: { name: 'Jobtitel', sortable: true },
  country: { name: 'Land', sortable: true },
  duration: { name: 'Dauer', sortable: true },
  option: { name: 'Option', sortable: true },
  activatedAt: { name: 'Online seit', sortable: true },
  activeUntil: { name: 'Online bis', sortable: true },
  deletedAt: { name: 'Gelöscht am', sortable: true },
  realViews: { name: 'Views', sortable: true },
  fakeViews: { name: 'Views', sortable: true },
  discountPercentage: { name: '%', sortable: true },
  netPrice: { name: 'Netto', sortable: true },
  paidWith: { name: 'Bez.', sortable: true },
};

const columnToSortField = (
  column: keyof typeof ADVERTISER_JOBS_COLUMNS
): NonNullable<Parameters<typeof useGetAllJobs>[0]['sort']>['field'] => {
  switch (column) {
    case 'id':
      return 'id';
    case 'activatedForServiceId':
      return 'activatedForServiceId';
    case 'title':
      return 'title';
    case 'country':
      return 'workCountry';
    case 'duration':
      return 'durationHours';
    case 'option':
      return 'isHotjob';
    case 'activatedAt':
      return 'activatedAt';
    case 'activeUntil':
      return 'activeUntil';
    case 'deletedAt':
      return 'deletedAt';
    case 'realViews':
      return 'realViews';
    case 'fakeViews':
      return 'fakeViews';
    case 'discountPercentage':
      return 'finalCustomerDiscountPercentage';
    case 'netPrice':
      return 'finalNetPrice';
    case 'paidWith':
      return 'paymentMethod';
    default:
      return 'id';
  }
};

const useDashboardAdvertiserDetailsJobsController = (
  advertiserId: number,
  defaultOrder: AdvertiserJobsOrder = { column: 'id', direction: 'desc' },
  filter?: Parameters<typeof useGetAllJobs>[0]['filter'],
  filterOperator: 'AND' | 'OR' = 'AND'
) => {
  const [sort, setSort] = useState(defaultOrder);
  const [page, setPage] = useState(1);
  const { data: jobsData, isLoading } = useGetAllJobs({
    page,
    filter: [...(filter ?? []), { field: 'advertiserId', operator: 'equals', value: advertiserId }],
    filterOperator,
    sort: {
      field: columnToSortField(sort.column),
      direction: sort.direction,
    },
  });
  const pageCount = useMemo(
    () => (jobsData ? Math.ceil(jobsData.count / Constants.ADMIN_PAGE_ENTRY_LIMIT) : 0),
    [jobsData]
  );

  const handleJobClick = (id: number) => {
    const popup = window.open(
      `/dashboard/anzeigen/${id}`,
      `job-${id}`,
      'width=1200,height=800,scrollbars=yes,resizable=yes,status=yes,dependent=yes'
    );
    if (popup) popup.focus();
  };

  const handleSort = (column: keyof typeof ADVERTISER_JOBS_COLUMNS) => {
    if (column === sort.column) setSort({ ...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    else setSort({ column, direction: 'asc' });
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage === page || newPage < 1 || newPage > pageCount) return;
    setPage(newPage);
  };

  useShallowCompareEffect(() => {
    setSort(defaultOrder);
  }, [defaultOrder]);

  return {
    jobs: jobsData ? jobsData.jobs : [],
    isLoading,
    pages: pageCount,
    page,
    currentSort: sort.column,
    handleJobClick,
    handleSort,
    handlePageChange,
  };
};

export default useDashboardAdvertiserDetailsJobsController;

import clsx from 'clsx';

interface InputProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'> {
  onChange?: (value: string) => void;
}
const Input: React.FC<InputProps> = ({ onChange, className, disabled, ...rest }) => {
  return (
    <input
      {...rest}
      disabled={disabled}
      className={clsx(
        'border border-border rounded-md px-3 py-2 focus:outline-none focus:shadow-md h-10',
        disabled && 'pointer-events-none',
        className
      )}
      onChange={(e) =>
        disabled
          ? () => {
              /* noop */
            }
          : onChange?.(e.target.value)
      }
    />
  );
};

export default Input;

import useDashboardJobsController, { JOB_COLUMNS, JobFilter } from '../../../controllers/Dashboard/Jobs';
import dayjs from 'dayjs';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import Button from '../../../components/Button';
import { useState } from 'react';
import Input from '../../../components/Input';
import { Helmet } from 'react-helmet';
import { AdvertiserType, JobState, PaymentMethod } from '@prisma/client';

interface DashboardJobsMainViewProps {
  hideColumns?: (keyof typeof JOB_COLUMNS)[];
  defaultOrderColumn?: keyof typeof JOB_COLUMNS;
  filter?: JobFilter | 'search';
  filterOperator?: 'AND' | 'OR';
  disableRowFormatting?: boolean;
}

const DashboardJobsMainView: React.FC<DashboardJobsMainViewProps> = ({
  defaultOrderColumn,
  hideColumns,
  filter,
  filterOperator,
  disableRowFormatting,
}) => {
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState<JobFilter | undefined>(undefined);
  const controller = useDashboardJobsController(
    { column: defaultOrderColumn ?? 'id', direction: 'desc' },
    filter !== 'search' ? filter : searchFilter,
    filter !== 'search' ? filterOperator : 'OR'
  );

  const handleSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const searchFields: NonNullable<JobFilter>[number]['field'][] = [
      'title',
      'advertiserBaseBusinessName',
      'advertiserBaseLastName',
      'advertiserBaseCity',
      'advertiserBaseCountry',
    ];
    if (search) {
      setSearchFilter(searchFields.map((field) => ({ field, operator: 'containsInsensitive', value: search.trim() })));
    }
  };

  return (
    <>
      <Helmet>
        <title>[JM] Anz Übersicht</title>
      </Helmet>
      {filter === 'search' && (
        <div className="flex items-center justify-center py-2 bg-light">
          <form className="flex items-center" onSubmit={handleSearchSubmit}>
            <Input onChange={(v) => setSearch(v)} value={search} className="w-96" />
            <Button
              title="Suche"
              className={clsx('ml-4', controller.isLoading && '!cursor-wait')}
              type="submit"
              disabled={controller.isLoading}
            />
          </form>
        </div>
      )}
      <table className="min-w-full text-left [&>*>tr>*]:px-1 [&>*>tr>*]:py-2 overflow-x-scroll">
        <thead className="lowercase bg-light text-2xs h-12">
          <tr>
            {Object.entries(JOB_COLUMNS).map(([key, value]) => {
              if (hideColumns && hideColumns.includes(key as keyof typeof JOB_COLUMNS)) {
                return null;
              }
              return (
                <th
                  key={key}
                  className={clsx(
                    'border-x border-x-border border-dotted',
                    controller.currentSort === key ? 'font-bold' : 'font-normal',
                    value.sortable && '!cursor-pointer hover:opacity-50'
                  )}
                  onClick={value.sortable ? () => controller.handleSort(key as keyof typeof JOB_COLUMNS) : undefined}>
                  {value.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-xs">
          {controller.jobs.map((job, idx) => (
            <tr
              key={job.id}
              className={clsx(
                'hover:bg-gray cursor-pointer',
                !disableRowFormatting &&
                  (() => {
                    if (job.state === JobState.UNCHECKED || job.state === JobState.EXPIRED) return 'text-[#999]';
                    else if (job.state === JobState.ACTIVE_UNCHECKED || (job.state === JobState.ACTIVE && job.isEdited))
                      return 'italic';
                    else if (job.state === JobState.DELETED_BY_USER) return 'text-[#999] italic';
                    else if (job.state === JobState.DELETED_BY_ADMIN) return 'text-[#999] italic line-through';
                  })()
              )}>
              {Object.entries(JOB_COLUMNS).map(([key]) => {
                if (hideColumns && hideColumns.includes(key as keyof typeof JOB_COLUMNS)) {
                  return null;
                }

                let data = '';
                let extraClassNames = '';
                let onClick = () => controller.handleJobClick(job.id);
                switch (key) {
                  case 'count':
                    data = ((controller.page - 1) * Constants.ADMIN_PAGE_ENTRY_LIMIT + idx + 1).toString();
                    break;
                  case 'id':
                    data = job.id.toString();
                    break;
                  case 'advertiserBaseServiceId':
                    data = job.advertiserBaseServiceId?.toString() ?? '';
                    break;
                  case 'activatedForServiceId':
                    data = job.activatedForServiceId?.toString() ?? job.bookingServiceId.toString();
                    break;
                  case 'advertiserType':
                    data = job.advertiserType === AdvertiserType.COMPANY ? 'g' : 'p';
                    break;
                  case 'title':
                    data = job.title;
                    break;
                  case 'country':
                    data = job.workCountry;
                    break;
                  case 'duration':
                    data = (job.durationHours / 24 / 7).toString();
                    break;
                  case 'option':
                    data = job.isHotjob ? '•' : '';
                    extraClassNames = 'text-center';
                    break;
                  case 'businessName':
                    data =
                      job.advertiserType === AdvertiserType.COMPANY
                        ? job.advertiserBaseBusinessName ?? ''
                        : '- privat -';
                    onClick = () => controller.handleAdvertiserClick(job.advertiserId);
                    break;
                  case 'salutation':
                    data = Constants.SALUTATION_OPTIONS[job.advertiserBaseSalutation];
                    break;
                  case 'lastName':
                    data = job.advertiserBaseLastName;
                    break;
                  case 'advertiserCountry':
                    data = job.advertiserBaseCountry;
                    break;
                  case 'advertiserCity':
                    data = job.advertiserBaseCity;
                    break;
                  case 'activatedAt':
                    data = job.activatedAt ? dayjs(job.activatedAt).format('DD.MM.YY') : '';
                    break;
                  case 'activeUntil':
                    data = job.activeUntil ? dayjs(job.activeUntil).format('DD.MM.YY') : '';
                    break;
                  case 'deletedAt':
                    data = job.deletedAt ? dayjs(job.deletedAt).format('DD.MM.YY') : '';
                    break;
                  case 'realViews':
                    data = job.realViews.toString();
                    break;
                  case 'fakeViews':
                    data = job.fakeViews.toString();
                    break;
                  case 'discountPercentage':
                    data = job.finalCustomerDiscountPercentage === 0 ? '' : `${job.finalCustomerDiscountPercentage}`;
                    extraClassNames = 'text-right';
                    break;
                  case 'netPrice':
                    data = `${Helpers.parsePrice(job.finalNetPrice)}`;
                    extraClassNames = 'text-right';
                    break;
                  case 'paidWith':
                    data = job.paymentMethod === PaymentMethod.PAYPAL ? 'pp' : '';
                    break;
                  default:
                    data = key;
                }
                return (
                  <td key={key} className={clsx('border border-border', extraClassNames)} onClick={onClick}>
                    {data}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex flex-row gap-2 w-full justify-center my-4">
        <Button onClick={() => controller.handlePageChange(1)} secondary className="!font-normal" title="|<" />
        <Button
          onClick={() => controller.handlePageChange(controller.page - 1)}
          secondary
          className="!font-normal"
          title="<"
        />
        {/* Show buttons for the current page (controller.page) in the center as well as the 3 pages before and after */}
        {Array.from({ length: 7 })
          .map((_, idx) => controller.page - 3 + idx)
          .filter((page) => page > 0 && page <= controller.pages)
          .map((page) => (
            <Button
              key={page}
              onClick={() => controller.handlePageChange(page)}
              secondary={page !== controller.page}
              className="!font-normal"
              title={page.toString()}
            />
          ))}
        <Button
          onClick={() => controller.handlePageChange(controller.page + 1)}
          secondary
          className="!font-normal"
          title=">"
        />
        <Button
          onClick={() => controller.handlePageChange(controller.pages)}
          secondary
          className="!font-normal"
          title=">|"
        />
      </div>
    </>
  );
};

export default DashboardJobsMainView;

import { useMutation, useQuery } from '@tanstack/react-query';
import { UseMutationTypeHelper, UseQueryTypeHelper } from '@jobmatic/shared/types';
import { trpc } from '../../utils/trpc';

export enum CouponQueryKeys {
  GET_ALL = 'COUPON_GET_ALL',
}

export const useGetAllCoupons = (
  data: Parameters<typeof trpc.coupon.getAll.query>[0],
  options?: UseQueryTypeHelper<typeof trpc.coupon.getAll.query>
) => useQuery([CouponQueryKeys.GET_ALL, data], () => trpc.coupon.getAll.query(data), options);

export const useDeleteCoupon = (
  options?: UseMutationTypeHelper<typeof trpc.coupon.delete.mutate, Parameters<typeof trpc.coupon.delete.mutate>[0]>
) => useMutation((data) => trpc.coupon.delete.mutate(data), options);

export const useCreateCoupons = (
  options?: UseMutationTypeHelper<typeof trpc.coupon.create.mutate, Parameters<typeof trpc.coupon.create.mutate>[0]>
) => useMutation((data) => trpc.coupon.create.mutate(data), options);

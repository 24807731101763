import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useVerifySession } from '../../hooks/query/Auth';
import { useEffect, useMemo, useState } from 'react';
import useSound from 'use-sound';
import HeaderNavigation from '../../components/HeaderNavigation';
import { trpc } from '../../utils/trpc';
import { useGetCurrentConfig, useGetGlobalNavigationCounts } from '../../hooks/query/Admin';
import { useGetAllJobs } from '../../hooks/query/Job';

const DashboardAdvertisersOutlet: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [playNotificationSound] = useSound('/assets/audio/notification.mp3');

  const [prevJobCount, setPrevJobCount] = useState<number | null>(null);

  const { mutate: verifySession } = useVerifySession({
    onSuccess: async (data) => {
      if (data.userType !== 'admin') {
        await trpc.auth.logout.mutate();
        navigate('/');
      }
    },
    onError: () => {
      navigate('/');
    },
  });

  useEffect(() => {
    verifySession();
  }, [verifySession, location.pathname]);

  const { data: systemConfig } = useGetCurrentConfig();
  const { data: counts } = useGetGlobalNavigationCounts();
  const { data: jobsAllData } = useGetAllJobs({});

  const navigation = useMemo(
    () => [
      {
        name: 'Anzeigen',
        items: [
          {
            name: `Prüfen (${counts?.jobsNewUnchecked ?? '?'}/${counts?.jobsEdited ?? '?'})`,
            path: '/dashboard/anzeigen/pruefen',
          },
          {
            name: `Online (${counts?.jobsOnline ?? '?'})`,
            path: '/dashboard/anzeigen/online',
          },
          {
            name: `Abgelaufen (${counts?.jobsExpiredToday ?? '?'})`,
            path: '/dashboard/anzeigen/abgelaufen',
          },
          {
            name: `Alle (${counts?.jobsAll ?? '?'})`,
            path: '/dashboard/anzeigen/alle',
          },
          {
            name: `User Deleted (${counts?.jobsDeletedByUserToday ?? '?'}/${counts?.jobsDeletedByUser ?? '?'})`,
            path: '/dashboard/anzeigen/geloescht',
          },
          {
            name: 'Suche',
            path: '/dashboard/anzeigen/suche',
          },
        ],
      },
      {
        name: 'Inserenten',
        items: [
          {
            name: `Heute neu (${counts?.advertisersNewToday ?? '?'})`,
            path: '/dashboard/inserenten/heute-neu',
          },
          {
            name: `Neue Daten (${counts?.advertisersNeedCheck ?? '?'})`,
            path: '/dashboard/inserenten/neue-daten',
          },
          {
            name: 'Heute aktiv',
            path: '/dashboard/inserenten/heute-aktiv',
          },
          {
            name: 'Gestern aktiv',
            path: '/dashboard/inserenten/gestern-aktiv',
          },
          {
            name: 'Alle',
            path: '/dashboard/inserenten/alle',
          },
          {
            name: 'Alle privat',
            path: '/dashboard/inserenten/alle-privat',
          },
          {
            name: 'Alle gewerblich',
            path: '/dashboard/inserenten/alle-gewerblich',
          },
          {
            name: `User Deleted (${counts?.advertisersDeletedByUserToday ?? '?'}/${
              counts?.advertisersDeletedByUser ?? '?'
            })`,
            path: '/dashboard/inserenten/geloescht',
          },
          {
            name: 'Suche',
            path: '/dashboard/inserenten/suche',
          },
        ],
      },
      {
        name: 'Administration',
        items: [
          {
            name: 'Allgemein',
            path: '/dashboard/administration/allgemein',
          },
          {
            name: 'Gutschein-Codes',
            path: '/dashboard/administration/gutschein-codes',
          },
          {
            name: `User Online (${counts?.advertisersOnline ?? '?'}/${counts?.guestsOnline ?? '?'})`,
            popup: {
              path: `/dashboard/administration/user-online`,
              id: 'user-online',
              options: 'width=900,height=500,scrollbars=yes,resizable=yes,status=yes,dependent=yes',
            },
          },
        ],
      },
    ],
    [counts]
  );

  useEffect(() => {
    if (jobsAllData === undefined) return;
    if (jobsAllData.count > (prevJobCount ?? 0)) {
      const playSound = prevJobCount !== null;
      setPrevJobCount(jobsAllData.count);
      if (playSound) {
        playNotificationSound();
      }
    }
  }, [jobsAllData, prevJobCount, playNotificationSound]);

  return (
    <div className="min-h-full">
      {systemConfig?.maintenanceEnabled && <div className="h-2.5 bg-error w-full" />}
      <HeaderNavigation navigation={navigation} lowercaseItems />
      <Outlet />
    </div>
  );
};

export default DashboardAdvertisersOutlet;

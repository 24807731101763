import { Helmet } from 'react-helmet';
import useDashboardVisitorTrackerController from '../../controllers/Dashboard/VisitorTracker';
import VisitorTrackerRow from '../../components/VisitorTrackerRow';
import clsx from 'clsx';

const DashboardVisitorTrackerView: React.FC = () => {
  const controller = useDashboardVisitorTrackerController();

  return (
    <div className="p-4 text-sm">
      <Helmet>
        <title>
          [JM] User Online ({`${controller.visitorTracksAdvertisers.length}/${controller.visitorTracksGuests.length}`})
        </title>
      </Helmet>
      <div className="flex flex-row gap-4">
        <div
          className={clsx('cursor-pointer', !controller.extendedView && 'font-bold')}
          onClick={() => controller.setExtendedView(false)}>
          simple
        </div>
        <div
          className={clsx('cursor-pointer', controller.extendedView && 'font-bold')}
          onClick={() => controller.setExtendedView(true)}>
          extended
        </div>
      </div>
      <table className="table-auto mt-8 border-separate border-spacing-x-8 -ml-8">
        {controller.visitorTracksAdvertisers.map((track) => (
          <VisitorTrackerRow
            extended={controller.extendedView}
            visitor={track}
            onPressAdvertiserName={controller.handlePressAdvertiser}
            key={track.id}
          />
        ))}
        {controller.visitorTracksGuests.map((track, idx) => (
          <VisitorTrackerRow
            extended={controller.extendedView}
            visitor={track}
            key={track.id}
            tdClassName={clsx(idx === 0 && 'pt-8')}
          />
        ))}
      </table>
    </div>
  );
};

export default DashboardVisitorTrackerView;

import {
  AdvertiserQueryKeys,
  useGetAdvertiserById,
  useUpdateAdvertiserSettings,
  useUpdateBaseData,
  useUpdateInvoiceData,
} from '../../../hooks/query/Advertiser';
import { useEffect, useState } from 'react';
import { AdvertiserType, PaymentMethod, Salutation } from '@prisma/client';
import { Constants, Helpers } from '@jobmatic/shared/utils';
import { useQueryClient } from '@tanstack/react-query';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { useGetInvoicesByAdvertiserId } from '../../../hooks/query/Invoice';
import { TRPCClientError } from '@trpc/client';

const useDashboardAdvertiserDetailsUpdateDataController = (advertiserId: number) => {
  const queryClient = useQueryClient();
  const { data: advertiser } = useGetAdvertiserById(advertiserId);
  const { data: invoices } = useGetInvoicesByAdvertiserId(advertiserId);
  const { mutate: updateSettings, isLoading: isUpdatingSettings } = useUpdateAdvertiserSettings({
    onSuccess: (data) => {
      queryClient.setQueryData([AdvertiserQueryKeys.GET_SINGLE, advertiserId], data);
      Helpers.scrollToTop();
    },
    onError: (e) => {
      alert(transformTRPCErrorToMessage(e));
    },
  });
  const { mutateAsync: updateBaseData, isLoading: isUpdatingBaseData } = useUpdateBaseData({
    onError: (e) => {
      alert(transformTRPCErrorToMessage(e));
    },
  });
  const { mutateAsync: updateInvoiceData, isLoading: isUpdatingInvoiceData } = useUpdateInvoiceData({
    onError: (e) => {
      let errorMessage: string = transformTRPCErrorToMessage(e);
      if (e instanceof TRPCClientError && e.data.apiError?.code === 'invalid_vat_id') {
        errorMessage += `\neVatR-Fehler: ${e.message}`;
      }
      alert(errorMessage);
    },
  });

  const [baseBusinessName, setBaseBusinessName] = useState('');
  const [baseBusinessAppendix, setBaseBusinessAppendix] = useState('');
  const [baseStreet, setBaseStreet] = useState('');
  const [baseZip, setBaseZip] = useState('');
  const [baseCity, setBaseCity] = useState('');
  const [baseCountry, setBaseCountry] = useState<keyof typeof Constants.COUNTRY_LIST>('DE');
  const [baseSalutation, setBaseSalutation] = useState<keyof typeof Constants.SALUTATION_OPTIONS>(Salutation.DIVERSE);
  const [baseFirstName, setBaseFirstName] = useState('');
  const [baseLastName, setBaseLastName] = useState('');
  const [basePhone, setBasePhone] = useState('');
  const [baseEmail, setBaseEmail] = useState('');
  const [invoiceBusinessName, setInvoiceBusinessName] = useState('');
  const [invoiceBusinessAppendix, setInvoiceBusinessAppendix] = useState('');
  const [invoiceStreet, setInvoiceStreet] = useState('');
  const [invoiceZip, setInvoiceZip] = useState('');
  const [invoiceCity, setInvoiceCity] = useState('');
  const [invoiceCountry, setInvoiceCountry] = useState<keyof typeof Constants.COUNTRY_LIST>('DE');
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [invoiceVatId, setInvoiceVatId] = useState('');
  const [invoicePaymentMethods, setInvoicePaymentMethods] = useState<PaymentMethod[]>([]);
  const [needsBaseCheck, setNeedsBaseCheck] = useState(false);
  const [needsInvoiceCheck, setNeedsInvoiceCheck] = useState(false);

  useEffect(() => {
    if (!advertiser || baseStreet.length > 0) return;
    setBaseBusinessName(advertiser.baseBusinessName || '');
    setBaseBusinessAppendix(advertiser.baseBusinessAppendix || '');
    setBaseStreet(advertiser.baseStreet);
    setBaseZip(advertiser.baseZip);
    setBaseCity(advertiser.baseCity);
    setBaseCountry(advertiser.baseCountry as keyof typeof Constants.COUNTRY_LIST);
    setBaseSalutation(advertiser.baseSalutation);
    setBaseFirstName(advertiser.baseFirstName);
    setBaseLastName(advertiser.baseLastName);
    setBasePhone(advertiser.basePhone);
    setBaseEmail(advertiser.newEmail ?? advertiser.email);
    setInvoiceBusinessName(advertiser.invoiceBusinessName || '');
    setInvoiceBusinessAppendix(advertiser.invoiceBusinessAppendix || '');
    setInvoiceStreet(advertiser.invoiceStreet || '');
    setInvoiceZip(advertiser.invoiceZip || '');
    setInvoiceCity(advertiser.invoiceCity || '');
    setInvoiceCountry((advertiser.invoiceCountry as keyof typeof Constants.COUNTRY_LIST) || 'DE');
    setInvoiceEmail(advertiser.invoiceEmail || '');
    setInvoiceVatId(advertiser.invoiceVatId || '');
    setInvoicePaymentMethods(advertiser.allowedPaymentMethods || []);
    setNeedsBaseCheck(advertiser.needsBaseCheck);
    setNeedsInvoiceCheck(advertiser.needsInvoiceCheck);
  }, [advertiser, baseStreet.length]);

  const handleUpdateBaseData = async () => {
    if (!advertiser) return;
    await updateBaseData({
      advertiserId: advertiser.id,
      businessName: advertiser?.type === AdvertiserType.COMPANY ? baseBusinessName.trim() : undefined,
      businessAppendix:
        advertiser?.type === AdvertiserType.COMPANY
          ? baseBusinessAppendix.trim().length
            ? baseBusinessAppendix.trim()
            : null
          : undefined,
      street: baseStreet.trim(),
      zip: baseZip.trim(),
      city: baseCity.trim(),
      country: baseCountry,
      salutation: baseSalutation,
      firstName: baseFirstName.trim(),
      lastName: baseLastName.trim(),
      phone: Helpers.parsePhoneNumber(basePhone),
      email: baseEmail.trim(),
    });
    updateSettings({
      advertiserId: advertiser.id,
      needsBaseCheck: advertiser.needsBaseCheck ? needsBaseCheck : undefined,
    });
  };

  const handleUpdateInvoiceData = async () => {
    if (!advertiser) return;
    if (advertiser.type === AdvertiserType.COMPANY) {
      await updateInvoiceData({
        advertiserId: advertiser.id,
        businessName: invoiceBusinessName.trim(),
        businessAppendix: invoiceBusinessAppendix.trim().length ? invoiceBusinessAppendix.trim() : null,
        street: invoiceStreet.trim(),
        zip: invoiceZip.trim(),
        city: invoiceCity.trim(),
        country: invoiceCountry,
        email: invoiceEmail.trim(),
        vatId:
          advertiser.invoiceVatId?.length && (invoices?.count ?? 0) > 0
            ? undefined
            : Constants.EU_COUNTRY_CODES.includes(invoiceCountry) &&
              advertiser.type === AdvertiserType.COMPANY &&
              invoiceVatId.trim().length > 0
            ? invoiceVatId.trim()
            : null,
      });
      updateSettings({
        advertiserId: advertiser.id,
        needsInvoiceCheck: advertiser.needsInvoiceCheck ? needsInvoiceCheck : undefined,
      });
    } else {
      updateSettings({
        advertiserId: advertiser.id,
        needsInvoiceCheck: undefined,
      });
    }
  };

  const handleCheckBaseBusinessName = () => {
    const url = new URL('https://www.google.de/search');
    url.searchParams.append('q', baseBusinessName.trim());

    window.open(url.toString(), '_blank');
  };

  const handleCheckBaseAddress = () => {
    const url = new URL('https://www.google.de/search');
    url.searchParams.append('q', `${baseStreet.trim()}, ${baseZip.trim()} ${baseCity.trim()}`);

    window.open(url.toString(), '_blank');
  };

  const handleUpdatePaymentMethods = (paymentMethods: PaymentMethod[]) => {
    if (!advertiser) return;
    setInvoicePaymentMethods(paymentMethods);
    updateSettings({
      advertiserId: advertiser.id,
      allowedPaymentMethods: paymentMethods,
    });
  };

  return {
    advertiser,
    invoiceCount: invoices?.count ?? 0,
    isUpdating: isUpdatingSettings || isUpdatingBaseData || isUpdatingInvoiceData,
    baseBusinessName,
    setBaseBusinessName,
    baseBusinessAppendix,
    setBaseBusinessAppendix,
    baseStreet,
    setBaseStreet,
    baseZip,
    setBaseZip,
    baseCity,
    setBaseCity,
    baseCountry,
    setBaseCountry,
    baseSalutation,
    setBaseSalutation,
    baseFirstName,
    setBaseFirstName,
    baseLastName,
    setBaseLastName,
    basePhone,
    setBasePhone,
    baseEmail,
    setBaseEmail,
    invoiceBusinessName,
    setInvoiceBusinessName,
    invoiceBusinessAppendix,
    setInvoiceBusinessAppendix,
    invoiceStreet,
    setInvoiceStreet,
    invoiceZip,
    setInvoiceZip,
    invoiceCity,
    setInvoiceCity,
    invoiceCountry,
    setInvoiceCountry,
    invoiceEmail,
    setInvoiceEmail,
    invoiceVatId,
    setInvoiceVatId,
    invoicePaymentMethods,
    setInvoicePaymentMethods: handleUpdatePaymentMethods,
    needsBaseCheck,
    setNeedsBaseCheck,
    needsInvoiceCheck,
    setNeedsInvoiceCheck,
    handleUpdateBaseData,
    handleUpdateInvoiceData,
    handleCheckBaseBusinessName,
    handleCheckBaseAddress,
  };
};

export default useDashboardAdvertiserDetailsUpdateDataController;

import { useParams } from 'react-router-dom';
import useDashboardAdvertiserDetailsLogoController from '../../../controllers/Dashboard/AdvertiserDetails/Logo';
import Button from '../../../components/Button';
import { AdvertiserType } from '@prisma/client';
import { Constants } from '@jobmatic/shared/utils';
import clsx from 'clsx';

const DashboardAdvertiserDetailsLogoView: React.FC = () => {
  const params = useParams();
  const controller = useDashboardAdvertiserDetailsLogoController(parseInt(params.id || '-1'));

  if (!controller.advertiser) return null; // TODO: Loading state
  if (controller.advertiser.type !== AdvertiserType.COMPANY) {
    return <div className="text-sm">Der Inserent ist privat, weshalb kein Logo hochgeladen werden kann.</div>;
  }

  return (
    <div className="text-sm">
      <div className="flex flex-col gap-4">
        <div
          className="w-64 h-64 border border-border flex items-center justify-center mt-16"
          {...controller.dropzone.getRootProps()}>
          <input
            {...controller.dropzone.getInputProps()}
            accept={Constants.ALLOWED_LOGO_MIME_TYPES.join(',')}
            multiple={false}
          />
          {controller.currentLogo || controller.logo ? (
            <img
              src={`data:${(controller.logo || controller.currentLogo)!.mimeType};base64,${
                (controller.logo || controller.currentLogo)!.base64
              }`}
              alt="Logo"
              className="w-56 h-56 object-contain cursor-pointer"
            />
          ) : (
            <div className="w-56 h-56 flex items-center justify-center bg-light cursor-pointer">
              <p className="text-dark text-center p-4">
                {controller.dropzone.isDragActive ? 'Datei hier ablegen' : 'Datei auswählen oder hier ablegen'}
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-between w-64">
          <Button
            secondary
            title="löschen"
            onClick={controller.handleDelete}
            disabled={controller.isUploading || controller.isDeleting || !controller.currentLogo}
            className={clsx(controller.isDeleting && '!cursor-wait')}
          />
          <Button
            title="upload"
            onClick={controller.handleSubmit}
            disabled={controller.isUploading || controller.isDeleting || !controller.logo}
            className={clsx(controller.isUploading && '!cursor-wait')}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardAdvertiserDetailsLogoView;

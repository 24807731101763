import { Helmet } from 'react-helmet';
import Button from '../components/Button';
import Input from '../components/Input';
import InputGroup from '../components/InputGroup';
import useLoginController from '../controllers/Login';
import clsx from 'clsx';

const LoginView: React.FC = () => {
  const controller = useLoginController();

  return (
    <>
      <Helmet>
        <title>[JM] Login</title>
      </Helmet>
      <div className="h-full w-full flex items-center justify-center px-4 mt-8">
        <div className="bg-light px-8 py-6 rounded-md ">
          <h1 className="text-2xl font-semibold">Jobmatic Admin</h1>
          {controller.error && <div className="mt-4">{controller.error.join(' ')}</div>}
          <form className="mt-8" onSubmit={controller.handleSubmit}>
            <div className="flex flex-col w-64 md:w-96">
              <InputGroup label="Benutzername">
                <Input
                  type="text"
                  autoComplete="username"
                  value={controller.username}
                  onChange={controller.setUsername}
                />
              </InputGroup>
              <InputGroup label="Passwort" className="mt-4">
                <Input
                  type="password"
                  autoComplete="current-password"
                  value={controller.password}
                  onChange={controller.setPassword}
                />
              </InputGroup>
              <Button
                type="submit"
                title="Login"
                className={clsx('mt-8 w-1/2 self-end', controller.isLoggingIn && '!cursor-wait')}
                disabled={controller.isLoggingIn}
                autoFocus
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginView;

import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';
import {
  AdvertiserQueryKeys,
  useCreateAdvertiserOneTimeLoginToken,
  useGetAdvertiserById,
  useGetLogo,
  useUpdateAdvertiserSettings,
} from '../../../hooks/query/Advertiser';
import { useGetAllServices } from '../../../hooks/query/Service';
import { useState } from 'react';
import { AccountState } from '@prisma/client';
import { Helpers } from '@jobmatic/shared/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useGetInvoicesByAdvertiserId } from '../../../hooks/query/Invoice';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { useGetAdvertiserNavigationCounts } from '../../../hooks/query/Admin';

const useDashboardAdvertiserDetailsSettingsController = (advertiserId: number) => {
  const queryClient = useQueryClient();
  const { data: advertiser } = useGetAdvertiserById(advertiserId, {
    onSuccess: (data) => {
      if (!data || initializedData) return;
      setBaseServiceId(data.baseServiceId);
      setState(data.state);
      setDiscount(data.discountPercentage);
      setFreeOptionJobsPerMonth(data.freeOptionJobsPerMonth);
      setInfo(data.notes || '');
      setAutoOn(data.autoActivateJobs);
      setReference(data.isReference);
      setNewsletter(data.receiveNewsletter);
      setTestAccount(data.isTestAccount);
      setInitializedData(true);
    },
  });
  const { data: logo } = useGetLogo(advertiserId);
  const { data: counts } = useGetAdvertiserNavigationCounts(advertiserId);
  const { data: invoices } = useGetInvoicesByAdvertiserId(advertiserId);
  const { data: services } = useGetAllServices();
  const { mutate: updateSettings, isLoading: isUpdating } = useUpdateAdvertiserSettings({
    onSuccess: (data) => {
      queryClient.setQueryData([AdvertiserQueryKeys.GET_SINGLE, advertiserId], data);
      Helpers.scrollToTop();
    },
    onError: (e) => {
      alert(transformTRPCErrorToMessage(e));
    },
  });
  const { mutate: createOneTimeLoginToken, isLoading: isLoggingInAsUser } = useCreateAdvertiserOneTimeLoginToken({
    onSuccess: (data) => {
      const service = services?.find((s) => s.id === loginAsUserServiceId);
      if (!service) return;
      const serviceUrl = `https://account.${new URL(service.url).hostname}`;
      const popup = window.open(
        `${serviceUrl}/?authOtp=${data.token}`,
        `user-login-${service.id}-${advertiserId}`,
        'width=1200,height=800,scrollbars=yes,resizable=yes,status=yes,dependent=yes'
      );
      if (popup) popup.focus();
    },
    onError: (e) => {
      alert(transformTRPCErrorToMessage(e));
    },
  });
  const copyToClipboard = useCopyToClipboard()[1];

  const [baseServiceId, setBaseServiceId] = useState(1);
  const [state, setState] = useState<AccountState>(AccountState.NEW);
  const [discount, setDiscount] = useState(0);
  const [freeOptionJobsPerMonth, setFreeOptionJobsPerMonth] = useState(0);
  const [info, setInfo] = useState('');
  const [autoOn, setAutoOn] = useState(false);
  const [reference, setReference] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [testAccount, setTestAccount] = useState(false);
  const [initializedData, setInitializedData] = useState(false);
  const [loginAsUserServiceId, setLoginAsUserServiceId] = useState<number | null>(null);

  const handleEmailClick = () => {
    if (!advertiser?.email) return;
    copyToClipboard(advertiser?.email);
  };

  const handlePhoneClick = () => {
    if (!advertiser?.basePhone) return;
    copyToClipboard(advertiser?.basePhone);
  };

  const handleUpdateSettings = () => {
    if (!advertiser) return;
    updateSettings({
      advertiserId: advertiser.id,
      baseServiceId,
      state,
      discountPercentage: discount || 0,
      freeOptionJobsPerMonth,
      notes: info.trim().length ? info.trim() : null,
      autoActivateJobs: autoOn,
      isReference: reference,
      receiveNewsletter: newsletter,
      isTestAccount: testAccount,
    });
  };

  const handleLoginAsUser = () => {
    if (!services?.find((s) => s.id === loginAsUserServiceId)) return;
    createOneTimeLoginToken({ id: advertiserId });
  };

  return {
    advertiser,
    logo,
    counts,
    invoices: invoices?.invoices || [],
    services: services || [],
    isUpdating,
    baseServiceId,
    setBaseServiceId,
    state,
    setState,
    discount,
    setDiscount,
    freeOptionJobsPerMonth,
    setFreeOptionJobsPerMonth,
    info,
    setInfo,
    autoOn,
    setAutoOn,
    reference,
    setReference,
    newsletter,
    setNewsletter,
    testAccount,
    setTestAccount,
    loginAsUserServiceId,
    setLoginAsUserServiceId,
    handleEmailClick,
    handlePhoneClick,
    handleUpdateSettings,
    handleLoginAsUser,
    isLoggingInAsUser,
  };
};

export default useDashboardAdvertiserDetailsSettingsController;

import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import {
  AdminQueryKeys,
  useGetCurrentConfig,
  useGetWebflowStatus,
  usePanicWebflow,
  useProfileApp,
  useResyncWebflow,
  useTriggerJobletter,
  useUpdateConfig,
  useUpdateViewsPerDay,
} from '../../hooks/query/Admin';
import { useQueryClient } from '@tanstack/react-query';
import { useImmer } from 'use-immer';
import { useState } from 'react';
import { ServiceQueryKeys, useGetAllServices } from '../../hooks/query/Service';

const useDashboardAdminController = () => {
  const queryClient = useQueryClient();
  useGetCurrentConfig({
    onSuccess: (data) => {
      if (initializedConfigData) return;
      setGlobalAutoOn(data.globalAutoOn);
      setVatRate(data.vatRate.toString().replace('.', ','));
      setMaintenanceReason(data.maintenanceReason ?? '');
      setMaintenanceEnabled(data.maintenanceEnabled ?? false);
      setInitializedConfigData(true);
    },
  });
  const { data: services } = useGetAllServices({
    onSuccess: (data) => {
      if (initializedServiceData) return;

      setViewsPerDay(
        data
          .map((service) => ({ [service.id.toString()]: service.fakeViewsPerDay }))
          .reduce((a, b) => ({ ...a, ...b }), {})
      );
      setInitializedServiceData(true);
    },
  });
  const { data: webflowStatus } = useGetWebflowStatus({ refetchInterval: 500 });
  const { mutate: updateConfig, isLoading: isUpdatingConfig } = useUpdateConfig({
    onSuccess: (data) => {
      queryClient.setQueryData([AdminQueryKeys.GET_CONFIG], data);
      setMaintenanceReason(data.maintenanceReason ?? '');
      setMaintenanceEnabled(data.maintenanceEnabled ?? false);
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: updateViewsPerDay, isLoading: isUpdatingViewsPerDay } = useUpdateViewsPerDay({
    onSuccess: () => {
      queryClient.refetchQueries([ServiceQueryKeys.GET_ALL]);
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: panicWebflow, isLoading: isActivatingPanic } = usePanicWebflow({
    onSuccess: () => {
      queryClient.refetchQueries([AdminQueryKeys.GET_WEBFLOW_STATUS]);
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: resyncWebflow, isLoading: isResyncing } = useResyncWebflow({
    onSuccess: () => {
      queryClient.refetchQueries([AdminQueryKeys.GET_WEBFLOW_STATUS]);
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: triggerJobletter, isLoading: isTriggeringJobletter } = useTriggerJobletter({
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const { mutate: profileApp, isLoading: isProfilingApp } = useProfileApp({
    onSuccess: ({ profileBase64 }) => {
      // download ZIP file from base64
      const link = document.createElement('a');
      link.href = `data:application/zip;base64,${profileBase64}`;
      link.download = 'profile.zip';
      link.click();
    },
  });

  const [initializedConfigData, setInitializedConfigData] = useState(false);
  const [initializedServiceData, setInitializedServiceData] = useState(false);
  const [globalAutoOn, setGlobalAutoOn] = useState(false);
  const [vatRate, setVatRate] = useState('');
  const [maintenanceReason, setMaintenanceReason] = useState('');
  const [maintenanceEnabled, setMaintenanceEnabled] = useState(false);
  const [viewsPerDay, setViewsPerDay] = useImmer<Record<string, number>>({});

  const handleChangeVatRate = (vatRate: string) => {
    setVatRate(vatRate.replace(/[^0-9,]/g, ''));
  };

  const handleSaveConfig = () => {
    updateConfig({
      globalAutoOn,
      vatRate: parseFloat(vatRate.replace(',', '.')),
    });
  };

  const handleEnableMaintenance = () => {
    updateConfig({
      maintenanceReason: maintenanceReason,
      maintenanceEnabled: true,
    });
  };

  const handleDisableMaintenance = () => {
    updateConfig({
      maintenanceEnabled: false,
    });
  };

  const handleUpdateViewsPerDay = () => {
    updateViewsPerDay(
      Object.entries(viewsPerDay)
        .map(([serviceId, fakeViewsPerDay]) => ({ serviceId: parseInt(serviceId), viewsPerDay: fakeViewsPerDay }))
        .filter(({ viewsPerDay }) => viewsPerDay > 0)
    );
  };

  return {
    services: services ?? [],
    webflowStatus,
    panicWebflow,
    resyncWebflow,
    triggerJobletter,
    profileApp,
    isUpdatingConfig,
    isActivatingPanic,
    isResyncing,
    isTriggeringJobletter,
    isProfilingApp,
    isUpdatingViewsPerDay,
    globalAutoOn,
    setGlobalAutoOn,
    vatRate,
    setVatRate: handleChangeVatRate,
    maintenanceReason,
    setMaintenanceReason,
    viewsPerDay,
    setViewsPerDay,
    maintenanceEnabled,
    handleSaveConfig,
    handleEnableMaintenance,
    handleDisableMaintenance,
    handleUpdateViewsPerDay,
  };
};

export default useDashboardAdminController;

import { Constants } from '@jobmatic/shared/utils';
import { CouponQueryKeys, useCreateCoupons, useDeleteCoupon, useGetAllCoupons } from '../../hooks/query/Coupon';
import { useEffect, useMemo, useState } from 'react';
import { useShallowCompareEffect } from 'react-use';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';
import { useQueryClient } from '@tanstack/react-query';
import { useGetAllServices } from '../../hooks/query/Service';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';
import { CouponType } from '@prisma/client';

export type CouponFilter = Parameters<typeof useDashboardCouponsController>[1];
export type CouponOrder = { column: keyof typeof COUPON_COLUMNS; direction: 'asc' | 'desc' };

export const COUPON_COLUMNS = {
  count: { name: '#', sortable: false },
  type: { name: 'Art', sortable: true },
  code: { name: 'Code', sortable: true },
  discountPercentage: { name: '%', sortable: true },
  service: { name: 'Service', sortable: true },
  createdAt: { name: 'angelegt am', sortable: true },
  redeemedAt: { name: 'eingelöst am', sortable: true },
  redeemedBy: { name: 'eingelöst von', sortable: true },
};

const columnToSortField = (
  column: keyof typeof COUPON_COLUMNS
): NonNullable<Parameters<typeof useGetAllCoupons>[0]['sort']>['field'] => {
  switch (column) {
    case 'type':
      return 'type';
    case 'code':
      return 'code';
    case 'discountPercentage':
      return 'discountPercentage';
    case 'service':
      return 'validForServiceId';
    case 'createdAt':
      return 'createdAt';
    case 'redeemedAt':
      return 'redeemedAt';
    case 'redeemedBy':
      return 'redeemedByAdvertiserName';
    default:
      return 'code';
  }
};

const useDashboardCouponsController = (
  defaultOrder: CouponOrder = { column: 'code', direction: 'desc' },
  filter?: Parameters<typeof useGetAllCoupons>[0]['filter'],
  filterOperator: 'AND' | 'OR' = 'AND'
) => {
  const queryClient = useQueryClient();
  const copyToClipboard = useCopyToClipboard()[1];
  const [sort, setSort] = useState(defaultOrder);
  const [page, setPage] = useState(1);
  const { data: couponsData, isLoading } = useGetAllCoupons({
    page,
    filter,
    filterOperator,
    sort: {
      field: columnToSortField(sort.column),
      direction: sort.direction,
    },
  });
  const pageCount = useMemo(
    () => (couponsData ? Math.ceil(couponsData.count / Constants.ADMIN_PAGE_ENTRY_LIMIT) : 0),
    [couponsData]
  );
  const { data: services } = useGetAllServices();

  const { mutateAsync: deleteCoupon } = useDeleteCoupon();
  const { mutate: createCoupons, isLoading: isGenerating } = useCreateCoupons({
    onSuccess: (data) => {
      queryClient.invalidateQueries([CouponQueryKeys.GET_ALL]);

      if (data.csvBase64) {
        const dataUrl = `data:text/csv;base64,${data.csvBase64}`;
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `coupons-${new Date().toISOString()}.csv`;
        link.click();
      }
    },
    onError: (e) => alert(transformTRPCErrorToMessage(e)),
  });
  const [deletingCoupons, setDeletingCoupons] = useState<string[]>([]);
  const [couponType, setCouponType] = useState<CouponType | null>(null);
  const [serviceId, setServiceId] = useState<number | null>(null);
  const [discount, setDiscount] = useState<number | null>(null);
  const [prefix, setPrefix] = useState<string>('');
  const [count, setCount] = useState<string>('');
  const [customCode, setCustomCode] = useState<string>('');
  const [generateCsv, setGenerateCsv] = useState<boolean>(false);

  useEffect(() => {
    if (couponType === CouponType.MULTI) {
      setPrefix('');
      setCount('1');
      setGenerateCsv(false);
    } else {
      setCustomCode('');
    }
  }, [couponType]);

  const handleCouponClick = (couponCode: string) => {
    copyToClipboard(couponCode);
  };

  const handleAdvertiserClick = (couponCode: string) => {
    const coupon = (couponsData?.coupons ?? []).find((coupon) => coupon.code === couponCode);
    if (!coupon?.redeemedByAdvertiserId) return;
    const popup = window.open(
      `/dashboard/inserenten/${coupon.redeemedByAdvertiserId}`,
      `inserent-${coupon.redeemedByAdvertiserId}`,
      'width=1200,height=800,scrollbars=yes,resizable=yes,status=yes,dependent=yes'
    );
    if (popup) popup.focus();
  };

  const handleDeleteCouponClick = async (couponCode: string, serviceId: number) => {
    if (deletingCoupons.includes(couponCode)) return;
    setDeletingCoupons((prev) => [...prev, couponCode]);
    try {
      await deleteCoupon({ code: couponCode, serviceId });
      queryClient.invalidateQueries([CouponQueryKeys.GET_ALL]);
    } catch (e) {
      alert(`Coupon-Code ${couponCode}\n\n` + transformTRPCErrorToMessage(e));
    } finally {
      setDeletingCoupons((prev) => prev.filter((code) => code !== couponCode));
    }
  };

  const handleGenerate = () => {
    if (
      !couponType ||
      !serviceId ||
      !discount ||
      (couponType === CouponType.SINGLE && (!count.length || !/^\d+$/.test(count) || parseInt(count) < 1)) ||
      (couponType === CouponType.MULTI && !customCode?.length)
    ) {
      return;
    }

    if (couponType === CouponType.SINGLE) {
      createCoupons({
        type: couponType,
        discountPercentage: discount,
        count: parseInt(count),
        serviceId,
        prefix: prefix.trim().length ? prefix.trim() : undefined,
        generateCsv,
      });
    } else {
      createCoupons({
        type: couponType,
        discountPercentage: discount,
        serviceId,
        generateCsv,
        code: customCode,
      });
    }
  };

  const handleSort = (column: keyof typeof COUPON_COLUMNS) => {
    if (column === sort.column) setSort({ ...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
    else setSort({ column, direction: 'asc' });
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage === page || newPage < 1 || newPage > pageCount) return;
    setPage(newPage);
  };

  useShallowCompareEffect(() => {
    setSort(defaultOrder);
  }, [defaultOrder]);

  return {
    coupons: couponsData ? couponsData.coupons : [],
    services: services ?? [],
    isLoading,
    isGenerating,
    pages: pageCount,
    page,
    currentSort: sort.column,
    handleCouponClick,
    handleAdvertiserClick,
    handleDeleteCouponClick,
    handleGenerate,
    handleSort,
    handlePageChange,
    deletingCoupons,
    couponType,
    setCouponType,
    serviceId,
    setServiceId,
    discount,
    setDiscount,
    prefix,
    setPrefix,
    count,
    setCount,
    customCode,
    setCustomCode,
    generateCsv,
    setGenerateCsv,
  };
};

export default useDashboardCouponsController;

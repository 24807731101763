import { VisitorType } from '@prisma/client';
import { useGetVisitorTracks } from '../../hooks/query/System';
import { useState } from 'react';
import dayjs from 'dayjs';

const useDashboardVisitorTrackerController = () => {
  const [extendedView, setExtendedView] = useState(false);
  const { data: visitorTracks } = useGetVisitorTracks({
    refetchInterval: 3000,
  });

  const handlePressAdvertiser = (id: number) => {
    const popup = window.open(
      `/dashboard/inserenten/${id}`,
      `advertiser-${id}`,
      'width=1200,height=800,scrollbars=yes,resizable=yes,status=yes,dependent=yes'
    );
    if (popup) popup.focus();
  };

  return {
    visitorTracksAdvertisers: (visitorTracks ?? [])
      .filter((track) => track.userType === VisitorType.ADVERTISER)
      .sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix())
      .sort((a, b) => a.serviceId - b.serviceId),
    visitorTracksGuests: (visitorTracks ?? [])
      .filter((track) => track.userType === VisitorType.GUEST)
      .sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix())
      .sort((a, b) => a.serviceId - b.serviceId),
    extendedView,
    setExtendedView,
    handlePressAdvertiser,
  };
};

export default useDashboardVisitorTrackerController;

import { useParams } from 'react-router-dom';
import useDashboardAdvertiserDetailsSettingsController from '../../../controllers/Dashboard/AdvertiserDetails/Settings';
import { AccountState, AdvertiserType, InvoiceState } from '@prisma/client';
import dayjs from 'dayjs';
import AdvertiserDetailsRow from '../../../components/AdvertiserDetailsRow';
import { Constants } from '@jobmatic/shared/utils';
import clsx from 'clsx';
import SelectBox from '../../../components/SelectBox';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import InputGroup from '../../../components/InputGroup';

const DashboardAdvertiserDetailsView: React.FC = () => {
  const params = useParams();
  const controller = useDashboardAdvertiserDetailsSettingsController(parseInt(params.id || '-1'));

  if (!controller.advertiser) return null; // TODO: Loading state

  return (
    <div className="lg:grid grid-cols-2 text-sm">
      <div className="flex flex-col gap-4 lg:pr-8 lg:border-r border-r-border">
        <div>
          <h2 className="text-xl font-semibold mb-4">Inserent</h2>
          <p>
            {controller.advertiser.type === AdvertiserType.COMPANY
              ? controller.advertiser.baseBusinessName
              : '- privater Inserent -'}
            {controller.advertiser.baseBusinessAppendix && (
              <>
                <br /> {controller.advertiser.baseBusinessAppendix}
              </>
            )}
          </p>
          <p>
            {controller.advertiser.baseStreet} <br />
            {controller.advertiser.baseZip} {controller.advertiser.baseCity}
            <br />
            {Constants.COUNTRY_LIST[controller.advertiser.baseCountry as keyof typeof Constants.COUNTRY_LIST]}
          </p>
          <p>
            {Constants.SALUTATION_OPTIONS[controller.advertiser.baseSalutation]} {controller.advertiser.baseFirstName}{' '}
            {controller.advertiser.baseLastName}
          </p>
          <p>
            <span className="cursor-pointer" onClick={controller.handlePhoneClick}>
              {controller.advertiser.basePhone}
            </span>{' '}
            <br />
            <span className="cursor-pointer" onClick={controller.handleEmailClick}>
              {controller.advertiser.email}
            </span>
            {controller.advertiser.newEmail && <span className="opacity-40"> {controller.advertiser.newEmail}</span>}
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-4">Infos</h2>
          {(controller.advertiser.state === AccountState.DELETED_BY_ADMIN ||
            controller.advertiser.state === AccountState.DELETED_BY_USER) && (
            <AdvertiserDetailsRow title="Löschgrund" className="mb-2">
              {controller.advertiser.deleteReason || <span className="italic">n/a</span>}
            </AdvertiserDetailsRow>
          )}
          <AdvertiserDetailsRow title="Registrierung">
            {dayjs(controller.advertiser.createdAt).format('DD.MM.YYYY')}{' '}
            <span className="opacity-40">{dayjs(controller.advertiser.createdAt).format('HH:mm')} Uhr</span>
          </AdvertiserDetailsRow>
          <AdvertiserDetailsRow title="Aktivierung">
            {controller.advertiser.activatedAt ? (
              <>
                {dayjs(controller.advertiser.activatedAt).format('DD.MM.YYYY')}{' '}
                <span className="opacity-40">{dayjs(controller.advertiser.activatedAt).format('HH:mm')} Uhr</span>
              </>
            ) : null}
          </AdvertiserDetailsRow>
          <AdvertiserDetailsRow title="Letzter Login" className="mt-2">
            {controller.advertiser.lastLogins.length > 0 ? (
              <>
                {dayjs([...controller.advertiser.lastLogins].reverse()[0]).format('DD.MM.YYYY')}{' '}
                <span className="opacity-40">
                  {dayjs([...controller.advertiser.lastLogins].reverse()[0]).format('HH:mm')} Uhr
                </span>
              </>
            ) : null}
          </AdvertiserDetailsRow>
          <AdvertiserDetailsRow title="Anzahl Logins" className="mt-2">
            {controller.advertiser.loginCount}
          </AdvertiserDetailsRow>
          <AdvertiserDetailsRow title="Anzeigen aktiv" className="mt-2">
            {controller.counts?.onlineJobs ?? 0}
          </AdvertiserDetailsRow>
          <AdvertiserDetailsRow title="Anzeigen gesamt">{controller.counts?.jobs ?? 0}</AdvertiserDetailsRow>
          {/* create an array from 2020 to current year and get the last 3 years to map over */}
          {Array.from({ length: dayjs().year() - 2019 }, (_, i) => dayjs().year() - i)
            .slice(0, 3)
            .map((year, idx) => (
              <AdvertiserDetailsRow title={`Umsatz ${year}`} className={clsx(idx === 0 && 'mt-2')}>
                {Number(
                  controller.invoices
                    .filter((i) => dayjs(i.invoiceDate).year() === year)
                    .reduce((prev, curr) => prev + curr.invoiceAmountNet, 0)
                )
                  .toFixed(2)
                  .replace('.', ',')}{' '}
                €
              </AdvertiserDetailsRow>
            ))}
          <AdvertiserDetailsRow title="Umsatz gesamt" className="mt-2">
            {Number(controller.invoices.reduce((prev, curr) => prev + curr.invoiceAmountNet, 0))
              .toFixed(2)
              .replace('.', ',')}{' '}
            €
          </AdvertiserDetailsRow>
          <AdvertiserDetailsRow title="offen" className="mt-2">
            {Number(
              controller.invoices
                .filter((i) => i.state === InvoiceState.OPEN)
                .reduce((prev, curr) => prev + curr.invoiceAmountNet, 0)
            )
              .toFixed(2)
              .replace('.', ',')}{' '}
            €
          </AdvertiserDetailsRow>
        </div>
        {controller.logo && (
          <img
            src={`data:${controller.logo.mimeType};base64,${controller.logo.base64}`}
            alt="Logo"
            className="max-w-24 max-h-24 aspect-square object-contain"
          />
        )}
      </div>
      <div className="flex flex-col gap-4 lg:pl-8 mt-8 lg:mt-0">
        <h2 className="text-xl font-semibold mb-4">Einstellungen</h2>
        <InputGroup label="Base-Service" className="-mt-2">
          <SelectBox
            value={controller.baseServiceId}
            onChange={(e) => controller.setBaseServiceId(parseInt(e.target.value))}
            options={controller.services.map((service) => ({
              value: service.id,
              label: service.name,
            }))}
          />
        </InputGroup>
        <InputGroup label="Status">
          <SelectBox
            value={controller.state}
            onChange={(e) => controller.setState(e.target.value as AccountState)}
            options={[
              {
                value: AccountState.NEW,
                label: 'registriert',
              },
              {
                value: AccountState.ACTIVE,
                label: 'aktiv',
              },
              {
                value: AccountState.DELETED_BY_USER,
                label: 'gelöscht - durch User',
              },
              {
                value: AccountState.DELETED_BY_ADMIN,
                label: 'gelöscht - durch Admin',
              },
              {
                value: AccountState.LOCKED,
                label: 'gesperrt',
              },
            ]}
          />
        </InputGroup>
        <InputGroup label="Rabatt in %">
          <SelectBox
            value={controller.discount}
            onChange={(e) => controller.setDiscount(parseInt(e.target.value))}
            options={Array.from({ length: 21 }, (_, i) => i * 5).map((num) => ({
              value: num,
              label: `${num}%`,
            }))}
          />
        </InputGroup>
        <InputGroup
          label={`Gratisanzeigen ${controller.services
            .filter((s) => s.freeOptionDuration !== null)
            .map((s) => s.name)
            .join(', ')}`}>
          <SelectBox
            value={controller.freeOptionJobsPerMonth}
            onChange={(e) => controller.setFreeOptionJobsPerMonth(parseInt(e.target.value))}
            options={Array.from({ length: 6 }, (_, i) => i).map((num) => ({
              value: num,
              label: num.toString(),
            }))}
          />
        </InputGroup>
        <InputGroup label="Infos">
          <textarea
            className="mt-1 w-full px-3 py-2 border border-border rounded h-24"
            value={controller.info}
            onChange={(e) => controller.setInfo(e.target.value)}
          />
        </InputGroup>
        <div className="opacity-40">Flags</div>
        <Checkbox
          label="Auto-Aktivierung"
          className="mt-1"
          checked={controller.autoOn}
          onChange={() => controller.setAutoOn(!controller.autoOn)}
        />
        <Checkbox
          label="nicht in Referenzen anzeigen"
          checked={!controller.reference}
          onChange={() => controller.setReference(!controller.reference)}
        />
        <Checkbox
          label="Newsletter"
          checked={controller.newsletter}
          onChange={() => controller.setNewsletter(!controller.newsletter)}
        />
        <Checkbox
          label="Test-Account"
          checked={controller.testAccount}
          onChange={() => controller.setTestAccount(!controller.testAccount)}
        />
        <Checkbox
          label={
            controller.advertiser.importId !== null
              ? `Importiert (insid: ${controller.advertiser.importId})`
              : `Importiert`
          }
          checked={controller.advertiser.importId !== null}
          onChange={() => {
            /* noop */
          }}
          disabled
        />
        <Button
          className={clsx('mt-1 self-end', controller.isUpdating && '!cursor-wait')}
          disabled={controller.isUpdating}
          title="speichern"
          onClick={() => controller.handleUpdateSettings()}
        />
        <div className="opacity-40">Als User einloggen</div>
        <SelectBox
          value={controller.loginAsUserServiceId ?? ''}
          onChange={(e) => controller.setLoginAsUserServiceId(parseInt(e.target.value))}
          options={controller.services
            .map((service) => ({
              value: service.id,
              label: service.name,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))}
          placeholder="Service"
          disabled={controller.isLoggingInAsUser}
        />
        <Button
          className={clsx('mt-1 self-end', controller.isLoggingInAsUser && '!cursor-wait')}
          disabled={controller.isLoggingInAsUser || !controller.loginAsUserServiceId}
          title="einloggen"
          onClick={() => controller.handleLoginAsUser()}
        />
      </div>
      <div className="col-span-2 flex flex-col gap-4 mt-8 py-8 lg:border-t border-t-border w-full overflow-x-scroll whitespace-nowrap">
        <div className="flex flex-row gap-8">
          <div className="shrink-0 w-32">5 letzte Logins</div>
          <div className="flex flex-col gap-1">
            {[...controller.advertiser.lastLogins].reverse().map((login) => (
              <div>
                {dayjs(login).format('DD.MM.YYYY')}{' '}
                <span className="opacity-40">{dayjs(login).format('HH:mm')} Uhr</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-1">
            {[...controller.advertiser.lastLoginServiceIds].reverse().map((serviceId) => (
              <div>{serviceId}</div>
            ))}
          </div>
          <div className="flex flex-col gap-1">
            {[...controller.advertiser.lastLoginIps].reverse().map((ip) => (
              <div>{ip}</div>
            ))}
          </div>
          <div className="flex flex-col gap-1">
            {[...controller.advertiser.lastLoginUserAgents].reverse().map((userAgent) => (
              <div>{userAgent}</div>
            ))}
          </div>
        </div>
        {controller.advertiser.activatedAt && (
          <div className="flex flex-row gap-8">
            <div className="w-32 shrink-0">Aktivierung</div>
            <div>
              {dayjs(controller.advertiser.activatedAt).format('DD.MM.YYYY')}{' '}
              <span className="opacity-40">{dayjs(controller.advertiser.activatedAt).format('HH:mm')} Uhr</span>
            </div>
            <div>{controller.advertiser.activationServiceId}</div>
            <div>{controller.advertiser.activatedAtIp}</div>
            <div>{controller.advertiser.activatedAtUserAgent}</div>
          </div>
        )}
        <div className="flex flex-row gap-8">
          <div className="w-32 shrink-0">Registrierung</div>
          <div>
            {dayjs(controller.advertiser.createdAt).format('DD.MM.YYYY')}{' '}
            <span className="opacity-40">{dayjs(controller.advertiser.createdAt).format('HH:mm')} Uhr</span>
          </div>
          <div>{controller.advertiser.signupServiceId}</div>
          <div>{controller.advertiser.createdAtIp}</div>
          <div>{controller.advertiser.createdAtUserAgent}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardAdvertiserDetailsView;

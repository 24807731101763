import { useQuery, useMutation } from '@tanstack/react-query';
import { UseQueryTypeHelper, UseMutationTypeHelper } from '@jobmatic/shared/types';
import { TRPCClientError } from '@trpc/client';
import { trpc } from '../../utils/trpc';

export enum AdvertiserQueryKeys {
  GET_ALL = 'ADVERTISER_GET_ALL',
  GET_SINGLE = 'ADVERTISER_GET_SINGLE',
  GET_LOGO = 'ADVERTISER_GET_LOGO',
}

export const useGetAllAdvertisers = (
  data: Parameters<typeof trpc.advertiser.getAll.query>[0],
  options?: UseQueryTypeHelper<typeof trpc.advertiser.getAll.query>
) => useQuery([AdvertiserQueryKeys.GET_ALL, data], () => trpc.advertiser.getAll.query(data), options);

export const useGetAdvertiserById = (
  advertiserId: number,
  options?: UseQueryTypeHelper<typeof trpc.advertiser.getById.query | (() => null)>
) =>
  useQuery([AdvertiserQueryKeys.GET_SINGLE, advertiserId], () => trpc.advertiser.getById.query(advertiserId), options);

export const useUpdateAdvertiserSettings = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.updateSettings.mutate,
    Parameters<typeof trpc.advertiser.updateSettings.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.updateSettings.mutate(data), options);

export const useUploadLogo = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.uploadLogo.mutate,
    Parameters<typeof trpc.advertiser.uploadLogo.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.uploadLogo.mutate(data), options);

export const useDeleteLogo = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.deleteLogo.mutate,
    Parameters<typeof trpc.advertiser.deleteLogo.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.deleteLogo.mutate(data), options);

export const useGetLogo = (
  advertiserId: number,
  options?: UseQueryTypeHelper<typeof trpc.advertiser.getLogo.query | (() => null)>
) =>
  useQuery(
    [AdvertiserQueryKeys.GET_LOGO, advertiserId],
    async () => {
      try {
        return await trpc.advertiser.getLogo.query({ advertiserId });
      } catch (error) {
        if (error instanceof TRPCClientError && error.data?.code === 'NOT_FOUND') return null;
        throw error;
      }
    },
    options
  );

export const useUpdateBaseData = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.updateBaseData.mutate,
    Parameters<typeof trpc.advertiser.updateBaseData.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.updateBaseData.mutate(data), options);

export const useUpdateInvoiceData = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.updateInvoiceData.mutate,
    Parameters<typeof trpc.advertiser.updateInvoiceData.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.updateInvoiceData.mutate(data), options);

export const useCreateAdvertiserOneTimeLoginToken = (
  options?: UseMutationTypeHelper<
    typeof trpc.advertiser.createOneTimeLoginToken.mutate,
    Parameters<typeof trpc.advertiser.createOneTimeLoginToken.mutate>[0]
  >
) => useMutation((data) => trpc.advertiser.createOneTimeLoginToken.mutate(data), options);

import { useParams } from 'react-router-dom';
import useDashboardAdvertiserDetailsSettingsController from '../../../controllers/Dashboard/AdvertiserDetails/UpdateData';
import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import InputGroup from '../../../components/InputGroup';
import Input from '../../../components/Input';
import { AdvertiserType } from '@prisma/client';
import SelectBox from '../../../components/SelectBox';
import { Constants } from '@jobmatic/shared/utils';
import clsx from 'clsx';

const DashboardAdvertiserDetailsUpdateDataView: React.FC = () => {
  const params = useParams();
  const controller = useDashboardAdvertiserDetailsSettingsController(parseInt(params.id || '-1'));

  if (!controller.advertiser) return null; // TODO: Loading state

  return (
    <div className="lg:grid grid-cols-2 text-sm">
      <form
        className="flex flex-col gap-4 lg:pr-8 lg:border-r border-r-border"
        onSubmit={(e) => {
          e.preventDefault();
          controller.handleUpdateBaseData();
        }}>
        <h2 className="text-xl font-semibold mb-4">Stammdaten</h2>
        {controller.advertiser.type === AdvertiserType.COMPANY && (
          <>
            <InputGroup label="Unternehmen" helper={controller.advertiser.baseBusinessNameOld}>
              <div className="grid grid-cols-3 gap-2 lg:grid-cols-4">
                <Input
                  value={controller.baseBusinessName}
                  onChange={controller.setBaseBusinessName}
                  autoComplete="off"
                  className="col-span-2 lg:col-span-3"
                />
                <Button
                  title="check"
                  onClick={controller.handleCheckBaseBusinessName}
                  secondary
                  className={clsx(controller.isUpdating && '!cursor-wait')}
                  disabled={controller.isUpdating}
                  type="button"
                />
              </div>
            </InputGroup>
            <InputGroup label="Zusatz" helper={controller.advertiser.baseBusinessAppendixOld}>
              <Input
                value={controller.baseBusinessAppendix}
                onChange={controller.setBaseBusinessAppendix}
                autoComplete="off"
              />
            </InputGroup>
          </>
        )}
        <InputGroup label="Straße / Hausnummer" helper={controller.advertiser.baseStreetOld}>
          <div className="grid grid-cols-3 gap-2 lg:grid-cols-4">
            <Input
              value={controller.baseStreet}
              onChange={controller.setBaseStreet}
              autoComplete="off"
              className="col-span-2 lg:col-span-3"
            />
            <Button
              title="check"
              onClick={controller.handleCheckBaseAddress}
              secondary
              className={clsx(controller.isUpdating && '!cursor-wait')}
              disabled={controller.isUpdating}
              type="button"
            />
          </div>
        </InputGroup>
        <div className="lg:grid grid-cols-3 gap-2">
          <InputGroup label="PLZ" helper={controller.advertiser.baseZipOld}>
            <Input value={controller.baseZip} onChange={controller.setBaseZip} autoComplete="off" />
          </InputGroup>
          <InputGroup label="Ort" className="lg:col-span-2 mt-4 lg:mt-0" helper={controller.advertiser.baseCityOld}>
            <Input value={controller.baseCity} onChange={controller.setBaseCity} autoComplete="off" />
          </InputGroup>
        </div>
        <InputGroup
          label="Land"
          helper={Constants.COUNTRY_LIST[controller.advertiser.baseCountryOld as keyof typeof Constants.COUNTRY_LIST]}>
          <SelectBox
            value={controller.baseCountry}
            onChange={(e) => controller.setBaseCountry(e.target.value as keyof typeof Constants.COUNTRY_LIST)}
            options={Object.entries(Constants.COUNTRY_LIST).map(([key, country]) => ({
              label: country,
              value: key,
            }))}
          />
        </InputGroup>
        <InputGroup
          label="Anrede"
          className="mt-2"
          helper={
            Constants.SALUTATION_OPTIONS[
              controller.advertiser.baseSalutationOld as keyof typeof Constants.SALUTATION_OPTIONS
            ]
          }>
          <SelectBox
            value={controller.baseSalutation}
            onChange={(e) => controller.setBaseSalutation(e.target.value as keyof typeof Constants.SALUTATION_OPTIONS)}
            options={Object.entries(Constants.SALUTATION_OPTIONS).map(([key, country]) => ({
              label: country,
              value: key,
            }))}
          />
        </InputGroup>
        <div className="lg:grid grid-cols-2 gap-2">
          <InputGroup label="Vorname" helper={controller.advertiser.baseFirstNameOld}>
            <Input value={controller.baseFirstName} onChange={controller.setBaseFirstName} autoComplete="off" />
          </InputGroup>
          <InputGroup label="Nachname" className="mt-4 lg:mt-0" helper={controller.advertiser.baseLastNameOld}>
            <Input value={controller.baseLastName} onChange={controller.setBaseLastName} autoComplete="off" />
          </InputGroup>
        </div>
        <InputGroup label="Telefon" helper={controller.advertiser.basePhoneOld}>
          <Input value={controller.basePhone} onChange={controller.setBasePhone} autoComplete="off" type="tel" />
        </InputGroup>
        <InputGroup label="E-Mail-Adresse" helper={controller.advertiser.newEmail ? controller.advertiser.email : null}>
          <Input value={controller.baseEmail} onChange={controller.setBaseEmail} autoComplete="off" type="email" />
        </InputGroup>
        <div className="flex flex-row self-end gap-4 items-center mt-1">
          {controller.advertiser.needsBaseCheck && (
            <Checkbox
              label="Stammdaten ok"
              checked={!controller.needsBaseCheck}
              onChange={(v) => controller.setNeedsBaseCheck(!v)}
            />
          )}
          <Button
            title="speichern"
            className={clsx(controller.isUpdating && '!cursor-wait')}
            disabled={controller.isUpdating}
            type="submit"
          />
        </div>
      </form>
      <form
        className="flex flex-col gap-4 lg:pl-8 mt-8 lg:mt-0"
        onSubmit={(e) => {
          e.preventDefault();
          controller.handleUpdateInvoiceData();
        }}>
        <h2 className="text-xl font-semibold mb-4">Rechnungsdaten</h2>
        {controller.advertiser.type === AdvertiserType.COMPANY ? (
          <>
            <InputGroup label="Unternehmen" helper={controller.advertiser.invoiceBusinessNameOld}>
              <Input
                value={controller.invoiceBusinessName}
                onChange={controller.setInvoiceBusinessName}
                autoComplete="off"
              />
            </InputGroup>
            <InputGroup label="Zusatz" helper={controller.advertiser.invoiceBusinessAppendixOld}>
              <Input
                value={controller.invoiceBusinessAppendix}
                onChange={controller.setInvoiceBusinessAppendix}
                autoComplete="off"
              />
            </InputGroup>
            <InputGroup label="Straße / Hausnummer" helper={controller.advertiser.invoiceStreetOld}>
              <Input value={controller.invoiceStreet} onChange={controller.setInvoiceStreet} autoComplete="off" />
            </InputGroup>
            <div className="lg:grid grid-cols-3 gap-2">
              <InputGroup label="PLZ" helper={controller.advertiser.invoiceZipOld}>
                <Input value={controller.invoiceZip} onChange={controller.setInvoiceZip} autoComplete="off" />
              </InputGroup>
              <InputGroup
                label="Ort"
                className="lg:col-span-2 mt-4 lg:mt-0"
                helper={controller.advertiser.invoiceCityOld}>
                <Input value={controller.invoiceCity} onChange={controller.setInvoiceCity} autoComplete="off" />
              </InputGroup>
            </div>
            <InputGroup
              label="Land"
              helper={
                Constants.COUNTRY_LIST[controller.advertiser.invoiceCountryOld as keyof typeof Constants.COUNTRY_LIST]
              }>
              <SelectBox
                value={controller.invoiceCountry}
                onChange={(e) => controller.setInvoiceCountry(e.target.value as keyof typeof Constants.COUNTRY_LIST)}
                options={Object.entries(Constants.COUNTRY_LIST).map(([key, country]) => ({
                  label: country,
                  value: key,
                }))}
                disabled={!!controller.advertiser.invoiceVatId?.length && controller.invoiceCount > 0}
              />
            </InputGroup>
            {controller.advertiser.type === AdvertiserType.COMPANY &&
              Constants.EU_COUNTRY_CODES.includes(controller.invoiceCountry) && (
                <InputGroup label="USt-ID*" helper={controller.advertiser.invoiceVatIdOld}>
                  <Input
                    value={controller.invoiceVatId}
                    onChange={controller.setInvoiceVatId}
                    autoComplete="off"
                    disabled={!!controller.advertiser.invoiceVatId?.length && controller.invoiceCount > 0}
                  />
                </InputGroup>
              )}
          </>
        ) : (
          <div>Der Inserent ist privat, weshalb keine Rechnungsdaten hinterlegt werden können.</div>
        )}
        <InputGroup label="Zahlungsweise">
          {Object.entries(Constants.PAYMENT_METHOD_OPTIONS).map(([key, value]) => (
            <Checkbox
              key={key}
              label={value}
              checked={controller.invoicePaymentMethods.includes(key as keyof typeof Constants.PAYMENT_METHOD_OPTIONS)}
              onChange={() => {
                if (controller.invoicePaymentMethods.includes(key as keyof typeof Constants.PAYMENT_METHOD_OPTIONS)) {
                  controller.setInvoicePaymentMethods(controller.invoicePaymentMethods.filter((v) => v !== key));
                } else {
                  controller.setInvoicePaymentMethods([
                    ...controller.invoicePaymentMethods,
                    key as keyof typeof Constants.PAYMENT_METHOD_OPTIONS,
                  ]);
                }
              }}
            />
          ))}
        </InputGroup>
        {controller.advertiser.type === AdvertiserType.COMPANY && (
          <InputGroup label="E-Mail-Adresse" helper={controller.advertiser.invoiceEmailOld}>
            <Input
              value={controller.invoiceEmail}
              onChange={controller.setInvoiceEmail}
              autoComplete="off"
              type="email"
            />
          </InputGroup>
        )}
        <div className="flex flex-row self-end gap-4 items-center mt-1">
          {controller.advertiser.type === AdvertiserType.COMPANY && controller.advertiser.needsInvoiceCheck && (
            <Checkbox
              label="Rechnungsdaten ok"
              checked={!controller.needsInvoiceCheck}
              onChange={(v) => controller.setNeedsInvoiceCheck(!v)}
            />
          )}
          <Button
            title="speichern"
            className={clsx(controller.isUpdating && '!cursor-wait')}
            disabled={controller.isUpdating}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default DashboardAdvertiserDetailsUpdateDataView;

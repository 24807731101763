import { useGetAllServices } from '../../../hooks/query/Service';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FeedParser } from '@prisma/client';
import { useImmer } from 'use-immer';
import {
  FeedQueryKeys,
  useCreateFeed,
  useDeleteFeed,
  useGetFeedsByAdvertiserId,
  useUpdateFeed,
} from '../../../hooks/query/Feed';
import dayjs from 'dayjs';
import { Helpers } from '@jobmatic/shared/utils';
import { transformTRPCErrorToMessage } from '@jobmatic/shared/api';

const useDashboardAdvertiserDetailsFeedsController = (advertiserId: number) => {
  const queryClient = useQueryClient();

  useGetFeedsByAdvertiserId(advertiserId, {
    onSuccess: (data) => {
      if (initializedData || !data) return; // TODO: why can data be null?
      setExistingFeeds(
        data.map((feed) => ({
          ...feed,
          description: feed.description ?? '',
          lastFetchedAt: feed.lastFetchedAt ? dayjs(feed.lastFetchedAt).toDate() : null,
        }))
      );
      setInitializedData(true);
    },
  });
  const { data: services } = useGetAllServices();
  const { mutate: createFeed, isLoading: isCreatingFeed } = useCreateFeed({
    onSuccess: (data) => {
      queryClient.setQueryData([FeedQueryKeys.GET_BY_ADVERTISER_ID, advertiserId], (oldData: any) => [
        ...(oldData || []),
        data,
      ]);
      setExistingFeeds((draft) => [
        ...draft,
        {
          ...data,
          description: data.description ?? '',
        },
      ]);
      Helpers.scrollToTop();
    },
    onError: (e) => {
      alert(transformTRPCErrorToMessage(e));
    },
  });
  const { mutate: uodateFeed, isLoading: isUpdatingFeed } = useUpdateFeed({
    onSuccess: (data) => {
      queryClient.setQueryData([FeedQueryKeys.GET_BY_ADVERTISER_ID, advertiserId], (oldData: any) => {
        return oldData.map((feed: any) => {
          if (feed.id === data.id) {
            return data;
          }
          return feed;
        });
      });
      setExistingFeeds((draft) => {
        const feed = draft.find((f) => f.id === data.id);
        if (feed) {
          feed.description = data.description ?? '';
          feed.url = data.url;
          feed.serviceId = data.serviceId;
          feed.parser = data.parser;
          feed.isHotjob = data.isHotjob;
        }
      });
    },
    onError: (e) => {
      alert(transformTRPCErrorToMessage(e));
    },
  });
  const { mutate: deleteFeed, isLoading: isDeletingFeed } = useDeleteFeed({
    onSuccess: (data) => {
      queryClient.setQueryData([FeedQueryKeys.GET_BY_ADVERTISER_ID, advertiserId], (oldData: any) => {
        return oldData.filter((feed: any) => feed.id !== data.id);
      });
      setExistingFeeds((draft) => {
        const index = draft.findIndex((feed) => feed.id === data.id);
        if (index > -1) {
          draft.splice(index, 1);
        }
      });
    },
    onError: (e) => {
      alert(transformTRPCErrorToMessage(e));
    },
  });

  const [initializedData, setInitializedData] = useState(false);
  const [url, setUrl] = useState('');
  const [serviceId, setServiceId] = useState(1);
  const [parser, setParser] = useState<FeedParser>(FeedParser.VONQ);
  const [description, setDescription] = useState('');
  const [isHotjob, setIsHotjob] = useState(false);
  const [existingFeeds, setExistingFeeds] = useImmer<
    {
      id: number;
      serviceId: number;
      url: string;
      description: string;
      parser: FeedParser;
      isHotjob: boolean;
      lastFetchedAt: Date | null;
    }[]
  >([]);

  const updateExistingFeed = (
    feedId: number,
    updater: (draft: (typeof existingFeeds)[number]) => (typeof existingFeeds)[number]
  ) => {
    setExistingFeeds((draft) => {
      return draft.map((feed) => {
        if (feed.id === feedId) {
          return updater(feed);
        }
        return feed;
      });
    });
  };

  const handleSubmitNewFeed = () => {
    createFeed({
      advertiserId,
      serviceId,
      feedUrl: url,
      parser,
      isHotjob,
      description: description.trim().length > 0 ? description.trim() : null,
    });
  };

  const handleSubmitFeedUpdate = (feedId: number) => {
    const updatedData = existingFeeds.find((f) => f.id === feedId);
    if (!updatedData) return;
    uodateFeed({
      id: feedId,
      advertiserId,
      serviceId: updatedData.serviceId,
      feedUrl: updatedData.url,
      parser: updatedData.parser,
      isHotjob: updatedData.isHotjob,
      description:
        updatedData.description && updatedData.description.trim().length > 0 ? updatedData.description.trim() : null,
    });
  };

  const handleSubmitDeleteFeed = (feedId: number) => {
    if (window.confirm('Sind Sie sicher, dass Sie diesen Feed löschen möchten?')) {
      deleteFeed({ id: feedId });
    }
  };

  return {
    services: services || [],
    isCreatingFeed,
    isUpdatingFeed,
    isDeletingFeed,
    url,
    setUrl,
    serviceId,
    setServiceId,
    parser,
    setParser,
    description,
    setDescription,
    isHotjob,
    setIsHotjob,
    existingFeeds,
    updateExistingFeed,
    handleSubmitNewFeed,
    handleSubmitFeedUpdate,
    handleSubmitDeleteFeed,
  };
};

export default useDashboardAdvertiserDetailsFeedsController;

import clsx from 'clsx';

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  secondary?: boolean;
}
const Button: React.FC<ButtonProps> = ({ title, className, secondary, disabled, ...rest }) => {
  return (
    <button
      {...rest}
      className={clsx(
        'px-4 py-2 rounded-md transition',
        secondary ? 'bg-light hover:bg-[#e2e2e2]' : 'bg-dark hover:bg-[#6e6e6e] text-white',
        disabled && 'opacity-50 !cursor-not-allowed',
        className
      )}
      disabled={disabled}>
      {title}
    </button>
  );
};

export default Button;

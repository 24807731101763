import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useVerifySession } from '../../../hooks/query/Auth';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useGetAdvertiserById } from '../../../hooks/query/Advertiser';
import { AdvertiserType } from '@prisma/client';
import { useGetJobById } from '../../../hooks/query/Job';
import { Helpers } from '@jobmatic/shared/utils';
import { useGetServiceById } from '../../../hooks/query/Service';
import BaseHeaderNavigation from '../../../components/BaseHeaderNavigation';
import { trpc } from '../../../utils/trpc';

const DashboardJobDetailsOutlet: React.FC = () => {
  const params = useParams();
  const jobId = useMemo(
    () => (params.id !== undefined && /^\d+$/.test(params.id) ? parseInt(params.id) : null),
    [params.id]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: verifySession } = useVerifySession({
    onSuccess: async (data) => {
      if (data.userType !== 'admin') {
        await trpc.auth.logout.mutate();
        navigate('/');
      }
    },
    onError: () => {
      navigate('/');
    },
  });
  const { data: job } = useGetJobById(jobId ?? 0, { enabled: !!jobId });
  const { data: advertiser } = useGetAdvertiserById(job?.advertiserId ?? 0, { enabled: !!job?.advertiserId });
  const { data: baseService } = useGetServiceById(job?.activatedForServiceId ?? 0, {
    enabled: !!job?.activatedForServiceId,
  });
  const { data: bookingService } = useGetServiceById(job?.bookingServiceId ?? 0, {
    enabled: !!job?.bookingServiceId,
  });

  useEffect(() => {
    verifySession();
  }, [verifySession, location.pathname]);

  const handleGoToAdvertiser = (id: number) => {
    const popup = window.open(
      `/dashboard/inserenten/${id}`,
      `advertiser-${id}`,
      'width=1200,height=800,scrollbars=yes,resizable=yes,status=yes,dependent=yes'
    );
    if (popup) popup.focus();
  };

  const handleGoToJob = (id: number) => {
    const popup = window.open(
      `/dashboard/anzeigen/${id}`,
      `job-${id}`,
      'width=1200,height=800,scrollbars=yes,resizable=yes,status=yes,dependent=yes'
    );
    if (popup) popup.focus();
  };

  const headerSep = <span className="mx-2">|</span>;
  return (
    <>
      <Helmet>
        <title>
          {job ? `[JM] Anz ${job.id}${job.templateJobId ? ` (${job.templateJobId})` : ''} – ${job.title}` : '[JM] Anz'}
        </title>
      </Helmet>
      <div className="min-h-full">
        <BaseHeaderNavigation
          childrenDesktop={
            job && (baseService || bookingService) && advertiser ? (
              <div className="inline">
                {`${Helpers.parseJobState(job.state)}`}
                {headerSep}
                {job.id}
                {job.templateJobId ? (
                  <>
                    {' '}
                    (
                    <span className="cursor-pointer" onClick={() => handleGoToJob(job.templateJobId!)}>
                      {job.templateJobId}
                    </span>
                    )
                  </>
                ) : null}
                {headerSep}
                {`${(baseService ?? bookingService)!.name}`}
                {headerSep}
                {``}
                <span onClick={() => handleGoToAdvertiser(job.advertiserId)} className="cursor-pointer">
                  {advertiser.type === AdvertiserType.COMPANY
                    ? advertiser.baseBusinessName
                    : `${advertiser.baseFirstName} ${advertiser.baseLastName}`}
                </span>
              </div>
            ) : (
              ''
            )
          }
        />
        <div className="p-8">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default DashboardJobDetailsOutlet;

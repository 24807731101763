import { Helpers } from '@jobmatic/shared/utils';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const MainOutlet: React.FC = () => {
  const location = useLocation();

  // Automatically scroll to top whenever pathname changes
  useEffect(() => {
    Helpers.scrollToTop({ jump: true });
  }, [location.pathname]);

  return <Outlet />;
};

export default MainOutlet;
